import React, { useState, useEffect, useRef } from "react";
import Users from "./Users";
import { Line, Bar, Pie, Doughnut, Polar, Radar } from "react-chartjs-2";
import Chartjs from "chart.js";
import { api } from "../../constants/Constants";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, CardContent } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    textAlign: "center",
    paddingBottom: "20px",
  },
  dangerGradient: {
    marginBottom: 70,
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)",
  },
  grid: {
    marginBottom: 70,
  },
  image: {
    marginLeft: "auto",
    display: "block",
    marginBottom: "20px",
    marginTop: "-20px",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  cardTitle: { color: "white" },
}));
const MODULE_URL = "/admin/dashboard";
const MODULE_URL_COUNTS = "/admin/dashboard/counts";

export default function Types(props) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [counts, setCounts] = useState([]);

  useEffect(() => {
    getAllCounts();
    getAll();
  }, []);
  const getAll = () => {
    api
      .get(`${MODULE_URL}`)
      .then((res) => {
        const response = res.data.data;
        setItems(response);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const getAllCounts = () => {
    api
      .get(`${MODULE_URL_COUNTS}`)
      .then((res) => {
        const response = res.data.data;
        setCounts(response);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const renderItem = (item) => {
    let type = item.type;
    let chartConfig = {
      type: type,
      data: {
        labels: item.labels,
        datasets: [
          {
            label: item.label,
            data: item.data,
            backgroundColor: item.backgroundColor,
            borderColor: item.backgroundColor,
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
        legend: {
          // //Disable chartjs legendclick
          // onClick: function (e) {
          //   e.stopPropagation();
          // },
          // //remove rectangle box next to the legend text
          // labels: {
          //   boxWidth: 0,
          // },
          display: false,
        },
      },
    };
    switch (item.type) {
      case "Bar":
        return (
          <Grid item xs={item.grid} className={classes.grid}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1" className={classes.title}>
                {item.label}
              </Typography>
              <Bar data={chartConfig.data} options={chartConfig.options} />{" "}
            </Paper>
          </Grid>
        );
      case "Line":
        return (
          <Grid item xs={item.grid} className={classes.grid}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1" className={classes.title}>
                {item.label}
              </Typography>
              <Line data={chartConfig.data} options={chartConfig.options} />{" "}
            </Paper>
          </Grid>
        );
      case "Pie":
        return (
          <Grid item xs={item.grid} className={classes.grid}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1" className={classes.title}>
                {item.label}
              </Typography>
              <Pie
                data={chartConfig.data}
                options={{
                  legend: { display: true, position: "right" },

                  datalabels: {
                    display: true,
                    color: "white",
                  },
                  tooltips: {
                    backgroundColor: "#5a6e7f",
                  },
                }}
              />
            </Paper>
          </Grid>
        );
      case "Doughnut":
        return (
          <Grid item xs={item.grid} className={classes.grid}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1" className={classes.title}>
                {item.label}
              </Typography>
              <Doughnut
                data={chartConfig.data}
                options={{
                  legend: { display: true, position: "right" },

                  datalabels: {
                    display: true,
                    color: "white",
                  },
                  tooltips: {
                    backgroundColor: "#5a6e7f",
                  },
                }}
              />
            </Paper>
          </Grid>
        );
      case "Polar":
        return (
          <Grid item xs={item.grid} className={classes.grid}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1" className={classes.title}>
                {item.label}
              </Typography>
              <Polar data={chartConfig.data} />
            </Paper>
          </Grid>
        );
      case "Radar":
        return (
          <Grid item xs={item.grid} className={classes.grid}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1" className={classes.title}>
                {item.label}
              </Typography>
              <Radar data={chartConfig.data} options={chartConfig.options} />
            </Paper>
          </Grid>
        );
    }
  };
  const renderItemCount = (item) => {
    return (
      <Grid item xs={item.grid}>
        <Card
          className={classes.dangerGradient}
          style={{ backgroundColor: item.background, width: item.width }}
        >
          <CardContent>
            <Typography
              variant="subtitle1"
              component="h4"
              className={classes.cardTitle}
              align="left"
            >
              Total {item.label}
            </Typography>
            <Typography
              variant="h3"
              component="h2"
              align="left"
              className={classes.cardTitle}
            >
              {item.value}
            </Typography>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="baseline"
            >
              <img
                className={classes.image}
                src={"/TotalCustomers.png"}
                alt="customers"
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  return (
    <div className={classes.root}>
      <h1>Dashboard</h1>
      <div>
        <Grid container spacing={3}>
          {counts &&
            counts.length > 0 &&
            counts.map((item) => renderItemCount(item))}
        </Grid>
        {/* <h2>Line Example</h2> */}
        {/* Charts */}
        <Grid container spacing={3}>
          {items && items.length > 0 && items.map((item) => renderItem(item))}
        </Grid>
      </div>
    </div>
  );
}
