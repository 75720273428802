import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default class AddDirectoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.name) {
        this.setState({ name: this.props.name });
      }
    } else if (prevProps.open && !this.props.open) {
      this.setState({ name: "" });
    }
  }

  handleNameChange = event => {
    this.setState({ name: event.target.value });
  };

  handleClose = () => {
    this.props.handleOpen(false);
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.content}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Folder name"
              fullWidth
              onChange={this.handleNameChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.props.handleOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.props.handleSuccess(this.state.name, this.props.id);
              }}
              color="primary"
            >
              {this.props.action}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
