import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import SortableTree from "react-sortable-tree";

const useStyles = makeStyles({
  tree: { height: "100%" },
});

function FoundersSorting(props) {
  const { open, handleClose, handleSuccess } = props;
  const classes = useStyles();
  const [tree, setTree] = useState([]);

  useEffect(() => {
    if (props.tree && props.open) {
      setTree(props.tree);
    } else if (!props.open) {
      setTree([]);
    }
  }, [props.tree, props.open]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Sort the elements</DialogTitle>
      <DialogContent dividers>
        <div className={classes.tree}>
          <SortableTree
            treeData={tree}
            onChange={(treeData) => {
              setTree(treeData);
            }}
            isVirtualized={false}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleSuccess(tree);
          }}
          color="primary"
        >
          Save
        </Button>
        <Button onClick={handleClose} color="action">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FoundersSorting;
