import React from "react";
import { useField } from "formik";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { DateTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles({
  DateTimeInput: {},
});

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

function DateTimeInput(props) {
  const [field] = useField(props);
  const { value, name, onBlur } = field;
  const { label, grid, setFieldValue, errors, setFieldError } = props;
  const classes = useStyles();
  const currentError = errors[name];
  return (
    <Grid item xs={grid}>
      <InputLabel>{label}</InputLabel>
      <DateTimePicker
        name={name}
        onBlur={onBlur}
        value={value}
        fullWidth
        error={Boolean(currentError)}
        onError={(error) => {
          if (error !== currentError) {
            setFieldError(name, error);
          }
        }}
        onChange={(date) => {
          setFieldValue(name, date.format(DATE_FORMAT), false);
        }}
      />
    </Grid>
  );
}

export default DateTimeInput;
