import { ContactlessOutlined } from "@material-ui/icons";
import React from "react";
import {Route, Redirect} from "react-router-dom";
import WithAppContext from "../context/WithAppContext";
class AuthenticatedRoute extends React.Component {
  render() {
    const {appReady, permission, module, checkAuthorization} = this.props;
    if (appReady) {
      if (
        checkAuthorization &&
        permission &&
        module &&
        !checkAuthorization(permission, module)
      ) {
        return <Redirect to="/" />;
      }
      return <Route {...this.props} />;
    }
    return null;
  }
}
export default WithAppContext(AuthenticatedRoute);
