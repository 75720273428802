import React, { useEffect, useRef, useState, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import NotificationsSteps from "./NotificationsSteps";
import { extractIds } from "../../helpers/General";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  title: "",
  body: "",
  cover_image: [],
  content: "",
  module_id: "",
  content_id: "",
  // Filtration
  filtrationType: 0,
  devices: 0,
  genders: 0,
  selectedProjects: JSON.stringify([]),
  selectedUserTypes: JSON.stringify([]),
  selectedUsers: [],
  sendDataTime: null,
};

const MODULE_URL = "admin/notifications";

function getStepContent(step) {
  switch (step) {
    case 0:
      return "Select campaign settings...";
    case 1:
      return "What is an ad group anyways?";
    case 2:
      return "This is the bit I really care about!";
    default:
      return "Unknown step";
  }
}

function NotificationsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          setItem(res.data.data);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            // validationSchema={Yup.object({})}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const {
                filtrationType,
                devices,
                genders,
                selectedProjects,
                selectedUserTypes,
                selectedUsers,
                title,
                body,
                content_id,
                module_id,
                content,
                type,
                sendDataTime,
                cover_image,
              } = input;
              let filters = {};
              if (filtrationType == 0) {
                if (devices) {
                  filters.devices = devices;
                }
                if (genders) {
                  filters.genders = genders;
                }
                if (selectedProjects && selectedProjects.length > 0) {
                  filters.projects = JSON.parse(selectedProjects);
                }
                if (selectedUserTypes && selectedUserTypes.length > 0) {
                  filters.type = JSON.parse(selectedUserTypes);
                }
              } else if (filtrationType == 1) {
                filters = { users: extractIds(selectedUsers) };
              }
              let data = new FormData();
              data.append("type", type);
              data.append("title", title);
              data.append("body", body);
              if (type == 0) {
                data.append("content", content);
                data.append("cover_image", cover_image);
              } else if (type == 1) {
                content_id && data.append("content_id", content_id);
                data.append("module_id", module_id);
              }
              if (sendDataTime) {
                data.append("send_date_time", sendDataTime);
              }
              data.append("segmentation", JSON.stringify(filters));
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue, values } = props;
              return (
                <form>
                  <NotificationsSteps
                    errors={errors}
                    values={values}
                    setFieldError={setFieldError}
                    setFieldValue={setFieldValue}
                    handleSubmit={handleSubmit}
                  />
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default NotificationsFormDialog;
