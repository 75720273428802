import decode from "jwt-decode";
import axios from "axios";
import { api } from "../constants/Constants";

const getToken = () => {
  try {
    return localStorage.getItem("jwtToken");
  } catch (err) {
    return "";
  }
};

export function isLoggedIn() {
  const token = getToken();
  return token && !isTokenExpired(token);
}

export const getValidToken = () => {
  const token = getToken();
  if (!token || isTokenExpired(token)) {
    return null;
  } else {
    return token;
  }
};

export function logout() {
  localStorage.removeItem("jwtToken");
  window.location.href = "/";
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(token.exp);
  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  if (expirationDate) {
    return expirationDate < new Date();
  } else {
    return false;
  }
}

function refreshToken(token) {
  api
    .post("admins/auth/refresh")
    .then((res) => {
      localStorage.setItem("jwtToken", res.data.access_token);
    })
    .catch((err) => {
      console.log(err);
    });
}
