import React, { createContext, Component } from "react";
import { api } from "../constants/Constants";
const AppContext = createContext({
  dark: false,
  permissions: [],
  appReady: false,
  themeObject: {},
  config: {},
});

export class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dark: false,
      permissions: [],
      languages: [],
      appReady: false,
      themeObject: {},
      config: {},
      admin: null,
      isLogged: false,
      isLoading: false,
      isRefreshing: false,
      snackBarObject: {
        open: false,
        message: "",
        type: "",
      },
      mediaLibrary: {
        open: false,
        tag: null,
        multiple: false,
      },
      hasUnitDeposit: 0,
    };
  }

  componentDidMount() {
    this.getCurrentAdmin();
    this.getLanguages();
    this.getThemeObject();
    // this.getConfig();
    this.getInitialMode();
  }

  getCurrentAdmin = () => {
    api
      .get("admins/auth/me")
      .then((res) => {
        this.setState({
          admin: res.data.data.admin,
          permissions: res.data.data.permissions,
          appReady: true,
          isLogged: true,
        });
      })
      .catch((err) => {
        this.setState({
          appReady: true,
        });
      });
  };

  getLanguages = () => {
    api
      .get("admin/languages")
      .then((res) => {
        let data = res.data.data.data;
        data = data.filter((lang) => {
          return lang.is_active;
        });
        this.setState({
          languages: data,
        });
      })
      .catch((err) => {
        this.setState({
          appReady: true,
        });
      });
  };

  getInitialMode = () => {
    const dark = localStorage.getItem("is_dark_mode");
    this.setState({ dark });
  };

  switchDarkMode = (dark) => {
    this.setState({ dark }, () => {
      localStorage.setItem("dark_mode", dark);
    });
  };

  // getConfig = () => {
  //   const config = {
  //     name: "Milango",
  //   };
  //   this.setState({ config });
  // };

  checkAuthorization = (permission, module) => {
    if (this.state.admin.is_super) return true;
    const permissions = this.state.permissions;
    return (
      permissions &&
      Object.keys(permissions).length > 0 &&
      permissions[module] &&
      permissions[module][permission] &&
      permissions[module][permission]["can"]
    );
  };

  getThemeObject = () => {
    api
      .get("admin/appSettings/theme")
      .then((res) => {
        let data = res.data.data;
        const themeObject = {
          primary: "#4F199D",
          secondary: "#F39870",
        };
        this.setState({
          themeObject: {
            primary: data.main_color,
            secondary: data.main_color2,
          },
          config: {
            name: data.name,
          },
          hasUnitDeposit: data.has_unit_deposit,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showSnack = (message, type) => {
    const newSnackBarObject = {
      open: true,
      message,
      type,
    };
    this.setState({ snackBarObject: newSnackBarObject });
  };

  closeSnack = () => {
    const newSnackBarObject = {
      open: false,
      message: "",
      type: "info",
    };
    this.setState({ snackBarObject: newSnackBarObject });
  };
  changeLoading = (isLoading) => {
    this.setState({ isLoading });
  };

  changeRefreshing = (isRefreshing) => {
    this.setState({ isRefreshing });
  };

  login = (token) => {
    localStorage.setItem("jwtToken", token);
    this.getCurrentAdmin();
  };

  logout = () => {
    localStorage.removeItem("jwtToken");
    this.setState({ admin: null, isLogged: false });
  };

  openMediaLibrary = (tag, multiple = false) => {
    this.setState({ mediaLibrary: { open: true, tag, multiple } });
  };

  closeMediaLibrary = () => {
    this.setState({
      mediaLibrary: { open: false, tag: null, multiple: false },
    });
  };

  triggerFileSelected = (files) => {
    const ImageSelectedEvent = new CustomEvent("media_selected", {
      detail: {
        files,
        tag: this.state.mediaLibrary.tag,
      },
    });
    document.dispatchEvent(ImageSelectedEvent);
  };

  render() {
    const {
      dark,
      appReady,
      themeObject,
      config,
      admin,
      isLogged,
      snackBarObject,
      isLoading,
      isRefreshing,
      mediaLibrary,
      languages,
      hasUnitDeposit,
    } = this.state;
    return (
      <AppContext.Provider
        value={{
          dark,
          appReady,
          themeObject,
          config,
          admin,
          isLogged,
          snackBarObject,
          isRefreshing,
          isLoading,
          mediaLibrary,
          languages,
          checkAuthorization: this.checkAuthorization,
          switchDarkMode: this.switchDarkMode,
          getTheme: this.getTheme,
          login: this.login,
          logout: this.logout,
          showSnack: this.showSnack,
          changeLoading: this.changeLoading,
          changeRefreshing: this.changeRefreshing,
          closeSnack: this.closeSnack,
          openMediaLibrary: this.openMediaLibrary,
          closeMediaLibrary: this.closeMediaLibrary,
          triggerFileSelected: this.triggerFileSelected,
          hasUnitDeposit,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
export default AppContext;
