import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import SelectInput from "../../../components/InputFields/SelectInput";
import ImageInput from "../../../components/InputFields/ImageInput";
import TextInput from "../../../components/InputFields/TextInput";
import TextAreaInput from "../../../components/InputFields/TextAreaInput";
import TranslatableTextAreaInput from "../../../components/InputFields/TranslatableTextAreaInput";
import TranslatableTextInput from "../../../components/InputFields/TranslatableTextInput";
import { api } from "../../../constants/Constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function ContentStep(props) {
  const classes = useStyles();
  const { errors, setFieldError, setFieldValue, values } = props;
  const [modules, setModules] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getModules();
  }, []);

  useEffect(() => {
    if (values.module_id) {
      getModulesContent(values.module_id);
    }
  }, [values.module_id]);

  const getModules = () => {
    api
      .get("admin/screens/inOptions")
      .then((res) => {
        setModules(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getModulesContent = (module_id) => {
    api
      .get(`admin/screens/${module_id}/contentOptions`)
      .then((res) => {
        const contents = res.data.data;
        setItems(res.data.data);
        if (contents.length <= 0) {
          setFieldValue("content_id", null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={classes.root}>
      <h3>Content</h3>
      <Grid container spacing={3}>
        <SelectInput
          name="type"
          label="Select notification type"
          grid={12}
          options={[
            { label: "Custom message", value: 0 },
            { label: "Existing content", value: 1 },
          ]}
          setFieldValue={setFieldValue}
        />
        <TranslatableTextInput
          name="title"
          label="Title"
          grid={6}
          setFieldValue={setFieldValue}
        />
        <TranslatableTextInput
          name="body"
          label="Body"
          grid={6}
          setFieldValue={setFieldValue}
        />
        {values.type == 0 && (
          <Fragment>
            <ImageInput
              name="cover_image"
              label="Cover Image"
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errors={errors}
              grid={12}
            />
            <TranslatableTextAreaInput
              name="content"
              label="Content"
              grid={12}
              setFieldValue={setFieldValue}
            />
          </Fragment>
        )}
        {values.type == 1 && (
          <Fragment>
            <SelectInput
              name="module_id"
              label="Select modules"
              grid={6}
              options={modules}
              setFieldValue={setFieldValue}
            />
            <SelectInput
              name="content_id"
              label="Select content"
              grid={6}
              options={items}
              disabled={items.length <= 0}
              setFieldValue={setFieldValue}
            />
          </Fragment>
        )}
      </Grid>
    </div>
  );
}
