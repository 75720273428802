import React from "react";
import { useField } from "formik";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { BlockPicker } from "react-color";

const useStyles = makeStyles({
  checkBoxInput: {},
});

function ColorPicker(props) {
  const [field] = useField(props);
  const { value, onChange, name } = field;
  const { label, grid, setFieldValue } = props;
  const classes = useStyles();
  const defaultColor = "#fff";
  const handleChange = (color) => {
    setFieldValue(name, color.hex);
  };
  return (
    <Grid item xs={grid}>
      <InputLabel style={{ marginBottom: 30 }}>{label}</InputLabel>
      <BlockPicker
        name={name}
        onChangeComplete={handleChange}
        color={value ? value : defaultColor}
      />
    </Grid>
  );
}

export default ColorPicker;
