import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ChipInput from "material-ui-chip-input";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles({
  textField: {},
  errorText: {
    textAlign: "center",
    color: "red",
  },
});

function TagsInput(props) {
  const [field, meta] = useField(props);
  const { value, onChange, onBlur, name } = field;
  const { error, touched } = meta;
  const { label, grid, type, disabled, rowsMax, setFieldValue } = props;
  const classes = useStyles();
  const [input, setInput] = useState([]);
  // let disabled = false;
  useEffect(() => {
    if (input.length <= 0 && value) {
      setInput(value.split(","));
    }
  }, [value]);

  useEffect(() => {
    setFieldValue(name, input.join(","));
  }, [input]);

  const handleChange = (chip) => {
    let temp = [...input];
    temp.push(chip);
    setInput(temp);
  };
  const renderErrors = () => {
    if (error && touched) {
      if (Array.isArray(error)) {
        return error.map((err) => (
          <InputLabel className={classes.errorText}>{err}</InputLabel>
        ));
      } else {
        return <InputLabel className={classes.errorText}>{error}</InputLabel>;
      }
    } else {
      return;
    }
  };
  return (
    <Grid item xs={grid}>
      <ChipInput
        label={label}
        fullWidth
        onAdd={(chips) => handleChange(chips)}
        value={input}
        onDelete={(chip, index) => {
          let temp = [...input];
          temp.splice(index, 1);
          setInput(temp);
        }}
      />{" "}
      {renderErrors()}
    </Grid>
  );
}

export default TagsInput;
