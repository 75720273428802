import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import BuildIcon from "@material-ui/icons/Build";
import ConfirmationDialog from "./ConfirmationDialog";
import DirectoryModal from "./DirectoryModal";
import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";

class UtilityBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfrimation: false,
      confirmationText: "",
      confirmationAction: "",
      openDirectoryModal: false,
      directoryModalTitle: "",
      directoryModalContent: "",
      directoryModalAction: "",
      selectedId: null,
    };
  }

  deleteSelected = () => {
    this.setState({
      openConfrimation: true,
      confirmationText: "Are you sure you want to delete this ?",
      confirmationAction: "Delete",
    });
  };

  handleCloseConfirmationDialog = () => {
    this.setState({ openConfrimation: false });
  };

  handleSuccessConfirmationDialog = () => {
    this.handleCloseConfirmationDialog();
    this.props.deleteSelected();
  };

  handleDirectoryModal = (value) => {
    this.setState({ openDirectoryModal: value });
  };

  handleAddDirectoryModal = () => {
    this.setState({
      openDirectoryModal: true,
      directoryModalTitle: "Add Folder",
      directoryModalContent: "Add folder to media library",
      directoryModalAction: "Add",
      selectedId: null,
    });
  };

  handleEditDirectory = () => {
    this.setState({
      openDirectoryModal: true,
      directoryModalTitle: "Edit Folder",
      directoryModalContent: "Edit media library folder",
      directoryModalAction: "Edit",
      selectedId: this.props.selected[0],
    });
  };

  mangeFolder = (name, id = null) => {
    this.handleDirectoryModal();
    this.props.mangeFolder(name, id);
  };

  render() {
    const {
      openConfrimation,
      confirmationText,
      confirmationAction,
      openDirectoryModal,
      directoryModalTitle,
      directoryModalContent,
      directoryModalAction,
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ConfirmationDialog
          open={openConfrimation}
          text={confirmationText}
          action={confirmationAction}
          handleClose={this.handleCloseConfirmationDialog}
          handleSuccess={this.handleSuccessConfirmationDialog}
        />
        <DirectoryModal
          open={Boolean(openDirectoryModal)}
          id={this.state.selectedId}
          title={directoryModalTitle}
          content={directoryModalContent}
          action={directoryModalAction}
          handleOpen={this.handleDirectoryModal}
          handleSuccess={this.mangeFolder}
        />
        <Grid container spacing={1} style={{ marginBottom: "20px" }}>
          <Grid item xs={2}>
            <Fab
              color="primary"
              aria-label="Add Folder"
              onClick={this.handleAddDirectoryModal}
            >
              <CreateNewFolderIcon style={{ color: "#fff" }} />
            </Fab>
          </Grid>
          <Grid item xs={2}>
            <Fab
              color="secondary"
              aria-label="Add Media"
              onClick={() => this.props.handleAddMediaModal(true)}
            >
              <InsertPhotoIcon />
            </Fab>
          </Grid>
          <Grid item xs={2}>
            <Fab
              className={classes.deleteBtn}
              aria-label="Delete Selected"
              disabled={this.props.selected.length <= 0}
              onClick={this.deleteSelected}
            >
              <DeleteIcon className={classes.greyIcon} />
            </Fab>
          </Grid>
          <Grid item xs={2}>
            <Fab
              className={classes.renameBtn}
              disabled={this.props.selected.length !== 1}
              onClick={this.handleEditDirectory}
            >
              <BuildIcon className={classes.greyIcon} />
            </Fab>
          </Grid>
          {!this.props.hideSelectAll && (
            <Grid item xs={2}>
              <Fab
                className={classes.doneBtn}
                disabled={
                  this.props.selected.length <= 0 ||
                  this.props.selectedType !== "media"
                }
                onClick={() => {
                  this.props.handleFilesSelected(this.props.selected);
                }}
              >
                <DoneAllIcon className={classes.greyIcon} />
              </Fab>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({
  deleteBtn: {
    background: red[600],
  },
  greyIcon: {
    color: grey[50],
  },
  renameBtn: {
    background: green[500],
  },
  doneBtn: {
    background: green[500],
  },
});
export default withStyles(styles)(UtilityBar);
