import React, { useState, useEffect, Fragment, useContext } from "react";
import { useField } from "formik";
import {
  Button,
  IconButton,
  InputLabel,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
} from "@material-ui/core";
import AppContext from "../../context/AppContext";
import CloseIcon from "@material-ui/icons/Close";

import { makeStyles } from "@material-ui/core/styles";
import { isObject } from "../../helpers/Validators";
import { extractIds } from "../../helpers/General";

const useStyles = makeStyles({
  gridList: {
    marginTop: "30px !important",
  },
  galleryInput: {
    display: "flex",
    flexDirection: "column",
    margin: 20,
    alignItems: "center",
  },
  buttonUpload: {
    color: "white",
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    // paddingTop: "-100% !important",
    position: "relative !important",
    // marginBottom: " -40px",
    // marginLeft: "20px",
  },
});

const empty_image = { id: null, url: "temp.png" };

function GalleryInput(props) {
  const [field, meta] = useField(props);
  const { value, onChange, onBlur, name } = field;
  const { error, touched } = meta;
  const { label, grid, setFieldValue } = props;
  const classes = useStyles();

  const context = useContext(AppContext);
  const { openMediaLibrary, closeMediaLibrary, mediaLibrary } = context;

  const [images, setImages] = useState([]);

  const handleEventListener = (event) => {
    if (event.detail && event.detail.tag === name) {
      handleImagesSelected(event.detail.files);
    }
  };

  useEffect(() => {
    if (mediaLibrary.open && mediaLibrary.tag === name) {
      document.addEventListener("media_selected", handleEventListener);
    } else {
      document.removeEventListener("media_selected", handleEventListener);
    }
    return () => {
      document.removeEventListener("media_selected", handleEventListener);
    };
  }, [mediaLibrary]);

  useEffect(() => {
    // in case of initial value is object
    if (value && Array.isArray(value)) {
      setImages(value);
      updateFieldValue(value);
    }
  }, [value]);

  const handleImagesSelected = (files) => {
    let newImages = [...images];
    let newImagesIds = extractIds(newImages);
    files.forEach((file) => {
      if (!newImagesIds.includes(file.id)) {
        newImages.push(file);
      }
    });
    setImages(newImages);
    updateFieldValue(newImages);
    closeMediaLibrary();
  };

  const updateFieldValue = (images) => {
    let fieldValue = extractIds(images);
    fieldValue = JSON.stringify(fieldValue);
    setFieldValue(name, fieldValue);
  };

  const removeImageFromGallery = (index) => {
    const temp = [...images];
    temp.splice(index, 1);
    setImages(temp);
    updateFieldValue(temp);
  };

  return (
    <Fragment>
      <Grid className={classes.galleryInput} item xs={grid}>
        <Button
          variant="contained"
          className={classes.buttonUpload}
          color="primary"
          onClick={() => {
            openMediaLibrary(name, true);
          }}
        >
          Upload
        </Button>
        <InputLabel style={{ textAlign: "center", marginTop: 30 }}>
          {label}
        </InputLabel>
        {images && images.length > 0 && (
          <GridList className={classes.gridList} cols={2}>
            {images.map((galleryImage, index) => (
              <GridListTile key={galleryImage.title}>
                <img src={galleryImage.url} alt={galleryImage.title} />
                <GridListTileBar
                  title={galleryImage.title}
                  style={{}}
                  actionIcon={
                    <IconButton
                      aria-label={`delete ${galleryImage.title}`}
                      onClick={() => removeImageFromGallery(index)}
                    >
                      <CloseIcon className={classes.title} color="secondary" />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))}
          </GridList>
        )}
      </Grid>
    </Fragment>
  );
}

export default GalleryInput;
