export const PENDING = "Pending";
export const REJECTED = "Rejected";
export const APPROVED = "Approved";

export const PENDING_CODE = "0";
export const REJECTED_CODE = "1";
export const APPROVED_CODE = "2";

export const options = [
  { label: PENDING, value: PENDING_CODE },
  { label: REJECTED, value: REJECTED_CODE },
  { label: APPROVED, value: APPROVED_CODE },
];
