import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import MultiSelectInput from "../../components/InputFields/MultiSelectInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import ProjectInput from "../../components/InputFields/ProjectsInput";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };

const MODULE_URL = "admin/activity_items";
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

function ActivityItemsFormDialog(props) {
  const { id, isOpen, handleSuccess, categoryId } = props;
  const classes = useStyles();
  const formRef = useRef();

  const empty = {
    name: "",
    is_active: 1,
    in_home: 0,
    menu_type: 0,
    sort: 0,
    description: "",
    facebook: "",
    instagram: "",
    youtube_url: "",
    google_maps_url: "",
    website: "",
    email: "",
    phone: "",
    from_to: "",
    google_place_id: "",
    lilsting_image: [],
    cover_image: [],
    gallery: [],
    activity_categories: [],
    pivot_tables_ids: [],
    facilitiesValue: [],
    activityCategoriesValue: categoryId ? [parseInt(categoryId)] : [],
  };

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const [activityCategoriesList, setActivityCategoriesList] = useState([]);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [facilitiesValue, setFacilitiesValue] = useState([]);
  const [activityCategoriesValue, setActivityCategoriesValue] = useState(
    categoryId ? [parseInt(categoryId)] : []
  );

  useEffect(() => {
    if (isOpen) {
      getActivityCategoriesSelectionList();
      getFacilitiesList();
      setFacilitiesValue([]);
    }
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let item = res.data.data;
          if (item.from) {
            item.from = moment(item.from, DATE_FORMAT);
          }
          if (item.to) {
            item.to = moment(item.to, DATE_FORMAT);
          }
          if (item.phone) {
            item.phone = item.phone.split(",");
          }
          setItem(item);
          let facilitiesValue = item.facilities.map((facility) => {
            return facility.id;
          });
          setFacilitiesValue(facilitiesValue);
          let activityCategoriesValue = item.activity_categories.map(
            (category) => {
              return category.id;
            }
          );
          setActivityCategoriesValue(activityCategoriesValue);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getActivityCategoriesSelectionList = () => {
    api
      .get(`admin/activity_categories/0/selection_list`)
      .then((response) => {
        let activitycategories = response.data.data;
        activitycategories = activitycategories.map((category) => {
          return { label: category.name, value: category.id };
        });
        setActivityCategoriesList(activitycategories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFacilitiesList = () => {
    api
      .get("admin/facilities/selection_list")
      .then((response) => {
        let facilities = response.data.data;
        facilities = facilities.map((facility) => {
          return { label: facility.name, value: facility.id };
        });
        setFacilitiesList(facilities);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle2"
              className={classes.flex}
              style={{
                color: headerTextColor,
              }}
            >
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              style={{
                color: headerTextColor,
              }}
              disabled={isDisabled()}
              onClick={handleSubmit}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(256, "Must be 256 characters or less")
                .required("Required"),
              description: Yup.string().required("Required"),
              activity_categories: Yup.array().required("Required").min(1),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              input.is_active = input.is_active ? 1 : 0;
              input.in_home = input.in_home ? 1 : 0;
              input.menu_type = input.menu_type ? 1 : 0;
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, values, setFieldError, setFieldValue } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <ProjectInput
                      setFieldValue={setFieldValue}
                      value={item.related_projects}
                    />
                    <TranslatableTextInput
                      name="name"
                      translations={item.allTranslations}
                      label="Please enter activity category name"
                      grid={6}
                      setFieldValue={setFieldValue}
                    />
                    <TextInput
                      name="email"
                      label="Please enter activity item email"
                      grid={6}
                    />
                    <TranslatableTextAreaInput
                      translations={item.allTranslations}
                      name="description"
                      label="Please enter activity item description"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    <Grid container>
                      <FieldArray
                        name="phone"
                        render={(arrayHelpers) => (
                          <>
                            {values.phone &&
                              values.phone.length > 0 &&
                              values.phone.map((item, index) => (
                                <React.Fragment key={index}>
                                  <TextInput
                                    name={`phone.${index}`}
                                    label="Please enter activity item phone"
                                    grid={6}
                                  />
                                  <Grid item xs={6}>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      Remove phone
                                    </Button>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add phone
                            </Button>
                          </>
                        )}
                      />
                    </Grid>
                    <TextInput
                      name="from_to"
                      label="Please enter activity item from to"
                      grid={6}
                    />
                    <TextInput
                      name="facebook"
                      label="Please enter activity item facebook"
                      grid={6}
                    />
                    <TextInput
                      name="instagram"
                      label="Please enter activity item instagram"
                      grid={6}
                    />
                    <TextInput
                      name="website"
                      label="Please enter activity item website"
                      grid={6}
                    />
                    <TextInput
                      name="youtube_url"
                      label="Please enter activity item youtube"
                      grid={6}
                    />
                    <TextInput
                      name="google_maps_url"
                      label="Please enter activity item maps url"
                      grid={6}
                    />
                    <TextInput
                      name="google_place_id"
                      label="Please enter activity item google place id"
                      grid={6}
                    />
                    <TextInput
                      name="rate"
                      label="Please enter activity item Rate"
                      grid={6}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={2}
                      setFieldValue={setFieldValue}
                    />
                    <SwitchInput
                      name="in_home"
                      label="In home"
                      grid={2}
                      setFieldValue={setFieldValue}
                    />
                    <MultiSelectInput
                      name="activity_categories"
                      label="Select activity categories"
                      grid={12}
                      options={activityCategoriesList}
                      setFieldValue={setFieldValue}
                      value={activityCategoriesValue}
                    />
                    <ImageInput
                      name="cover_image"
                      label="Cover Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={6}
                    />
                    <GalleryInput
                      name="gallery"
                      label="Gallery Images"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <h3>Featured Item </h3>
                    <SwitchInput
                      name="show_featured"
                      label="Show Featured"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    <TextInput
                      name="featured_item_header"
                      label="Please enter activity item google place id"
                      grid={6}
                    />
                    <TextInput
                      name="featured_item_title"
                      label="Please enter activity item google place id"
                      grid={6}
                    />
                    <TextInput
                      name="featured_item_description"
                      label="Please enter activity item google place id"
                      grid={6}
                    />
                    <ImageInput
                      name="featured_image"
                      label="Featured Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <h3>Menu</h3>
                    <SwitchInput
                      name="show_menu"
                      label="Show Menu"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    <p>PDF</p>
                    <SwitchInput
                      name="menu_type"
                      label="Images"
                      grid={8}
                      setFieldValue={setFieldValue}
                    />
                    {getFormValues() && getFormValues().menu_type == 1 ? (
                      <GalleryInput
                        name="menu_gallery"
                        label="Menu Gallery"
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        errors={errors}
                        grid={12}
                      />
                    ) : (
                      <ImageInput
                        name="menu_pdf"
                        label="Menu PDF"
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        errors={errors}
                        grid={12}
                      />
                    )}
                    <MultiSelectInput
                      name="facilities"
                      label="Select facilities"
                      grid={12}
                      options={facilitiesList}
                      setFieldValue={setFieldValue}
                      value={facilitiesValue}
                    />
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default ActivityItemsFormDialog;
