import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import SelectInput from "../../components/InputFields/SelectInput";
import Select from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TagsInput from "../../components/InputFields/TagsInput";
import { date } from "yup/lib/locale";
import { yupRequiredTranslatableString } from "../../helpers/Validators";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 400,
    marginRight: "1vw",
    marginBottom: "1vw",
    marginTop: "2vw",
    border: "1px solid rgb(55, 17, 109, 0.5)",

    // This line disable the blue border
    boxShadow: "none",
    borderRadius: 4,
    "&:hover": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
      borderRadius: 4,
    },
  }),
};
// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const MODULE_URL = "admin/form_fields";

function FormFieldsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();
  const { id, isOpen, handleSuccess, formID } = props;
  const empty = {
    name: "",
    is_active: 0,
    required: 0,
    input_type: 0,
    form_id: formID ? parseInt(formID) : 0,
    placeholder: "",
    default_value: "",
    agreement_description: "",
    number_of_uploads: 0,
    allowed_extensions: "",
    year_from: 0,
    year_to: 0,
    option_source: 0,
  };

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const [inputType, setInputType] = useState("");
  const [dateType, setDateType] = useState("");
  const [optionSource, setOptionSource] = useState("");

  const inputOptions = [
    { label: "Text Field", value: 1 },
    { label: "Text Area", value: 2 },
    { label: "Drop Down", value: 3 },
    { label: "Multiple Select", value: 4 },
    { label: "Radio Buttons", value: 5 },
    { label: "Checkbox", value: 6 },
    { label: "Agreement", value: 7 },
    { label: "Date and Time", value: 8 },
    { label: "File", value: 9 },
  ];
  const dateTypes = [
    { label: "Date", value: 1 },
    { label: "Time", value: 2 },
    { label: "Date And Time", value: 3 },
  ];

  const optionsSource = [
    { label: "Projects", value: 1 },
    { label: "Units", value: 2 },
    { label: "Types of Property", value: 3 },
    { label: "Facilities", value: 4 },
    { label: "Departments", value: 5 },
    { label: "Users Tags", value: 6 },
    { label: "Host Event Types", value: 7 },
    { label: "Host Event Locations", value: 8 },
    { label: "Host Event Services", value: 9 },
    { label: "Custom", value: 10 },
  ];

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          inputOptions.map((item) => {
            if (item.value == tmp.input_type) {
              setInputType(item);
            }
          });
          if (tmp.date_type) {
            dateTypes.map((item) => {
              if (item.value == tmp.date_type) {
                setDateType(item);
              }
            });
          }
          optionsSource.map((item) => {
            if (item.value == tmp.option_source) {
              setOptionSource(item);
            }
          });
          setItem(tmp);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
      setInputType("");
      setDateType("");
      setOptionSource("");
    }
  }, [id, isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };
  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";

  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              disabled={isDisabled()}
              onClick={handleSubmit}
              style={{
                color: headerTextColor,
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              name: yupRequiredTranslatableString(),
              form_id: Yup.number().required().min(1),
              number_of_uploads:
                inputType.value == 9 ? Yup.number().required().min(1) : "",
              year_from: Yup.number(),
              year_to: Yup.number(),
              placeholder:
                inputType.value == 1 ||
                inputType.value == 2 ||
                inputType.value == 3 ||
                inputType.value == 4 ||
                inputType.value == 8
                  ? yupRequiredTranslatableString()
                  : "",
              default_value:
                inputType.value == 1 || inputType.value == 2
                  ? yupRequiredTranslatableString()
                  : "",
              option_source:
                inputType.value == 3 ||
                inputType.value == 4 ||
                inputType.value == 5 ||
                inputType.value == 6
                  ? Yup.number().required().min(1)
                  : "",
              date_type:
                inputType.value == 8 ? Yup.number().required().min(1) : "",
              agreement_description:
                inputType.value == 7 ? yupRequiredTranslatableString() : "",

              custom_options:
                (inputType.value == 3 ||
                  inputType.value == 4 ||
                  inputType.value == 5 ||
                  inputType.value == 6) &&
                optionSource.value == 10
                  ? Yup.string().required()
                  : "",
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = {
                ...values,
                form_id: formID,
                input_type: inputType.value,
                option_source: optionSource ? optionSource.value : null,
                date_type: dateType ? dateType.value : null,
              };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  console.log("error", err);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                    Object.keys(err.response.data).map((key) => {
                      showSnack(`${key}: ${err.response.data[key]}`, "error");
                    });
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue, touched, values } =
                props;

              return (
                <form>
                  <Grid container spacing={3}>
                    {item.is_default == 1 ? (
                      <TranslatableTextInput
                        translations={item.allTranslations}
                        setFieldValue={setFieldValue}
                        name="name"
                        label="Please enter field name"
                        grid={6}
                        disabled
                      />
                    ) : (
                      <TranslatableTextInput
                        translations={item.allTranslations}
                        setFieldValue={setFieldValue}
                        name="name"
                        label="Please enter field name"
                        grid={6}
                      />
                    )}

                    <FormControl fullWidth>
                      {item.is_default == 1 ? (
                        <>
                          <Select
                            name="input_type"
                            label="Input Type"
                            styles={customStyles}
                            grid={12}
                            options={inputOptions}
                            value={inputType}
                            onChange={(value) => setInputType(value)}
                            isDisabled={true}
                          />
                          <p className="MuiFormHelperText-root Mui-error">
                            {touched && errors.input_type}
                          </p>
                        </>
                      ) : (
                        <>
                          <Select
                            name="input_type"
                            label="Input Type"
                            styles={customStyles}
                            grid={12}
                            options={inputOptions}
                            value={inputType}
                            onChange={(value) => setInputType(value)}
                          />
                          <p className="MuiFormHelperText-root Mui-error">
                            {touched && errors.input_type}
                          </p>
                        </>
                      )}
                      {item.is_default == 1 ? (
                        <SwitchInput
                          name="required"
                          label="Is required"
                          grid={4}
                          setFieldValue={setFieldValue}
                          disabled={true}
                        />
                      ) : (
                        <SwitchInput
                          name="required"
                          label="Is required"
                          grid={4}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </FormControl>
                    {(inputType.value == 1 || inputType.value == 2) && (
                      <>
                        <TranslatableTextInput
                          translations={item.allTranslations}
                          setFieldValue={setFieldValue}
                          name="placeholder"
                          label="Please enter field placeholder"
                          grid={6}
                        />
                        <TranslatableTextInput
                          translations={item.allTranslations}
                          setFieldValue={setFieldValue}
                          name="default_value"
                          label="Please enter field default value"
                          grid={6}
                        />
                      </>
                    )}
                    {(inputType.value == 3 ||
                      inputType.value == 4 ||
                      inputType.value == 8) && (
                      <>
                        <TranslatableTextInput
                          translations={item.allTranslations}
                          setFieldValue={setFieldValue}
                          name="placeholder"
                          label="Please enter field placeholder"
                          grid={6}
                        />
                      </>
                    )}
                    {inputType.value == 7 && (
                      <>
                        <TranslatableTextAreaInput
                          translations={item.allTranslations}
                          setFieldValue={setFieldValue}
                          name="agreement_description"
                          label="Please enter field agreement information"
                          grid={12}
                        />
                      </>
                    )}
                    {inputType.value == 8 && (
                      <>
                        <Grid xs={12}>
                          <Select
                            name="date_type"
                            label="Date Type"
                            styles={customStyles}
                            grid={12}
                            options={dateTypes}
                            value={dateType}
                            onChange={(value) => {
                              setDateType(value);
                              values.date_type = value.value;
                            }}
                          />
                          <p className="MuiFormHelperText-root Mui-error">
                            {touched && errors.date_type}
                          </p>
                        </Grid>
                        {dateType.value !== 2 && dateType !== "" && (
                          <>
                            <TextInput
                              name="year_from"
                              label="Please enter year from"
                              grid={6}
                            />
                            <TextInput
                              name="year_to"
                              label="Please enter year to"
                              grid={6}
                            />
                          </>
                        )}
                      </>
                    )}

                    {inputType.value == 9 && (
                      <>
                        {/* <TagsInput
                          name="allowed_extensions"
                          label="Please enter allowed extensions"
                          grid={6}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          errors={errors}
                        /> */}
                        <TextInput
                          name="number_of_uploads"
                          label="Please enter allowed number of uploads"
                          grid={6}
                        />
                      </>
                    )}

                    {(inputType.value == 3 ||
                      inputType.value == 4 ||
                      inputType.value == 5 ||
                      inputType.value == 6) && (
                      <>
                        <Grid xs={12}>
                          <Select
                            name="option_source"
                            label="Options Source"
                            styles={customStyles}
                            grid={12}
                            options={optionsSource}
                            value={optionSource}
                            onChange={(value) => {
                              setOptionSource(value);
                              values.option_source = value.value;
                            }}
                          />
                          <p className="MuiFormHelperText-root Mui-error">
                            {touched && errors.option_source}
                          </p>
                        </Grid>
                        {optionSource.value == 10 && (
                          <TagsInput
                            name="custom_options"
                            label="Please enter custom options"
                            grid={6}
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            errors={errors}
                          />
                        )}
                      </>
                    )}

                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default FormFieldsFormDialog;
