import React, { useContext, useEffect, useState } from "react";
import { useField } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import { InputLabel, Grid, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../context/AppContext";

import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
// import "tinymce/skins/ui/oxide/skin.min.css";
// import "tinymce/skins/ui/oxide/content.min.css";
// import "tinymce/skins/content/default/content.min.css";
// import "tinymce/skins/ui/oxide-dark/skin.min.css";
// import "tinymce/skins/ui/oxide-dark/content.min.css";
// import "tinymce/skins/content/dark/content.min.css";

const useStyles = makeStyles({
  textField: {},
  inputLabel: {
    marginBottom: 10,
  },
  errorText: {
    textAlign: "center",
    color: "red",
  },
});

function TranslatableTextAreaInput(props) {
  const [field, meta] = useField(props);
  const { value, name, onBlur } = field;
  const { error, touched } = meta;
  const { label, grid, setFieldValue, translations } = props;
  const [translation, setTranslation] = useState("");

  const classes = useStyles();

  const context = useContext(AppContext);

  useEffect(() => {
    if (translations) {
      setTranslation(translations[name]);
    }
  }, [translations]);

  useEffect(() => {
    if (translation) {
      setFieldValue(name, JSON.stringify(translation));
    } else {
      setFieldValue(name, '{"en":""}');
    }
  }, [translation]);

  const onTranslationChange = (content, language) => {
    setTranslation({ ...translation, [language]: content });
  };

  const handleChange = (content, editor) => {
    setFieldValue(name, content);
  };
  const { languages, dark } = context;

  // Need fix
  // Dark will force over white if loaded before
  useEffect(() => {
    if (dark) {
      require("tinymce/skins/ui/oxide-dark/skin.min.css");
      require("tinymce/skins/ui/oxide-dark/content.min.css");
      require("tinymce/skins/content/dark/content.min.css");
    } else {
      require("tinymce/skins/ui/oxide/skin.min.css");
      require("tinymce/skins/ui/oxide/content.min.css");
      require("tinymce/skins/content/default/content.min.css");
    }
  }, [dark]);

  const renderErrors = () => {
    if (error && touched) {
      if (Array.isArray(error)) {
        return error.map((err) => (
          <InputLabel className={classes.errorText}>{err}</InputLabel>
        ));
      } else {
        return <InputLabel className={classes.errorText}>{error}</InputLabel>;
      }
    } else {
      return;
    }
  };

  return languages.map((language) => {
    return (
      <Grid item xs={grid}>
        <FormHelperText
          className={{ ...classes.inputLabel, fontcolor: "sold 1px red" }}
        >
          {label + ` in ${language.name}`}
        </FormHelperText>
        <Editor
          style={{ border: "sold 1px red" }}
          // initialValue={translation[language.code]}
          init={{
            // skin: false,
            // content_css: false,
            skin: context.dark ? "oxide-dark" : "oxide",
            content_css: context.dark ? "dark" : false,
            height: 200,
            menubar: true,
            plugins: ["link image", "table paste", "code"],
            toolbar:
              "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code",
            directionality: language.is_rtl ? "rtl" : "ltr",
          }}
          value={translation[language.code]}
          textareaName={name}
          onEditorChange={(content) =>
            onTranslationChange(content, language.code)
          }
          onBlur={onBlur}
        />
        {renderErrors()}
      </Grid>
    );
  });
}

export default TranslatableTextAreaInput;
