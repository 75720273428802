import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import SwitchInput from "../../../components/InputFields/SwitchInput";
import DateTimeInput from "../../../components/InputFields/DateTimeInput";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function ScheduleStep(props) {
  const classes = useStyles();
  const { errors, setFieldError, setFieldValue, values } = props;
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <SwitchInput
          name="scheduleType"
          label="Send Now"
          grid={12}
          setFieldValue={setFieldValue}
        />
        {values.scheduleType != 1 && (
          <Fragment>
            <DateTimeInput
              name="sendDataTime"
              label="Choose time to send the notification"
              grid={12}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              errors={errors}
            />
          </Fragment>
        )}
      </Grid>
    </div>
  );
}
