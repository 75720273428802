import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  TextField,
  DialogActions,
  Button,
  Slide,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import { api } from "../../constants/Constants";
import { withStyles } from "@material-ui/core/styles";
import AppContext from "../../context/AppContext";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  button: {},
});

const MODULE_URL = "/admin/users";

function UnitMates(props) {
  const context = useContext(AppContext);
  const { changeRefreshing } = context;
  const [devices, setDevices] = useState([]);
  const { isVisible, id } = props;
  const classes = styles();

  useEffect(() => {
    if (isVisible && id) {
      getUnitMates(id);
    }
  }, [isVisible, id]);

  const getUnitMates = (user_id) => {
    changeRefreshing(true);
    api
      .get(MODULE_URL + `/devices/${user_id}`)
      .then((res) => {
        changeRefreshing(false);
        setDevices(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        changeRefreshing(false);
      });
  };
  const handleClose = () => {
    changeRefreshing(false);
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        open={isVisible}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <AppBar elevation={0} className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle2"
              color="inherit"
              className={classes.flex}
            >
              Units & Mates
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Brand</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>OS</TableCell>
                <TableCell>First install</TableCell>
                <TableCell>Last update</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices &&
                devices.length > 0 &&
                devices.map((device) => (
                  <TableRow>
                    <TableCell>{device.brand}</TableCell>
                    <TableCell>{device.model}</TableCell>
                    <TableCell>{device.device_type}</TableCell>
                    <TableCell>{device.os == 1 ? "IOS" : "Android"}</TableCell>
                    <TableCell>{device.first_install}</TableCell>
                    <TableCell>{device.last_update}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="textPrimary">
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(UnitMates);
