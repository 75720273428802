import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import AdminsFilters from "./AdminsFilters";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import WithAppContext from "../../context/WithAppContext";
import GetAppIcon from "@material-ui/icons/GetApp";
import Filters from "../../components/Filters";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
  root2: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root3: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
  },
});

const useStyles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  tableCellStyles: {
    padding: "15px",
  },
  resendButton: {
    marginLeft: theme.spacing(2),
  },
});

class EnhancedTableToolbar extends Component {
  render() {
    const { classes, handleFilter, numSelected, handleExport, selected } =
      this.props;
    return (
      <div>
        <Toolbar className={classNames(classes.root)}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              Admins
            </Typography>
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            <Tooltip title="Filters">
              <Filters url="/admins/filters" handleFilter={handleFilter} />
            </Tooltip>
            <Tooltip title="export all">
              <IconButton aria-label="export all" onClick={handleExport}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
            {numSelected > 0 && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="Delete"
                  onClick={() => this.props.handleDeleteButton(selected)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Toolbar>
        <div className={classes.root2}></div>
      </div>
    );
  }
}
EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

class AdminsListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      order: "desc",
      orderBy: "id",
      deleteItems: [],
      confirmDialogOpen: false,
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({
        selected: this.props.data.map((n) => n.id),
      });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleDeleteButton = (toBeDeleted) => {
    if (!this.props.checkAuthorization("delete", "Admins")) {
      this.props.showSnack(
        "You don't have permission to delete admins",
        "error"
      );
    } else {
      this.handleOpenConfirmation(toBeDeleted);
    }
  };

  handleOpenConfirmation = (toBeDeleted) => {
    this.setState({ deleteItems: toBeDeleted, confirmDialogOpen: true });
  };

  handleCloseConfirmation = () => {
    this.setState({ confirmDialogOpen: false, deleteItems: [] });
  };

  deleteItems = () => {
    let toBeDeleted = this.state.deleteItems;
    this.handleCloseConfirmation();
    this.props.handleDelete(toBeDeleted);
    this.setState({ selected: [] });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, data, handleOpenEditFrom, handleFilter, handleExport } =
      this.props;
    const { selected } = this.state;
    const numSelected = selected.length;
    const rowCount = data.length;
    return (
      <Paper className={classes.root}>
        <DeleteConfirmationDialog
          isOpen={this.state.confirmDialogOpen}
          id={this.state.deleteItems}
          handleCloseDeleteConfirmationDialog={this.handleCloseConfirmation}
          title="Deleting"
          text="Are you sure you want to perform this action?"
          handleConfirmDelete={this.deleteItems}
        />
        <EnhancedTableToolbar
          handleFilter={handleFilter}
          data={this.props.data}
          numSelected={numSelected}
          selected={selected}
          handleDeleteButton={this.handleDeleteButton}
          handleExport={handleExport}
        />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected === rowCount}
                  onChange={this.handleSelectAllClick}
                />
              </TableCell>
              {/* <TableCell>ID</TableCell> */}
              <TableCell>First name</TableCell>
              <TableCell>Last name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Controls</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data !== null &&
              data.map((row) => {
                const isSelected = this.isSelected(row.id);
                return (
                  <TableRow
                    key={row.id}
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    selected={isSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected}
                        onClick={(event) => this.handleClick(event, row.id)}
                      />
                    </TableCell>
                    {/* <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell> */}
                    <TableCell component="th" scope="row">
                      {row.first_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.last_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Tooltip title="Edit">
                        <IconButton
                          className={classes.button}
                          aria-label="Delete"
                          onClick={() => handleOpenEditFrom(row.id)}
                        >
                          <EditIcon color="action" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          className={classes.button}
                          aria-label="Delete"
                          onClick={() => this.handleDeleteButton(row.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
export default WithAppContext(withRouter(withStyles(useStyles)(AdminsListing)));
