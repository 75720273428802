import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Right from "@material-ui/icons/ChevronRight";
import Left from "@material-ui/icons/ChevronLeft";
import LastPage from "@material-ui/icons/LastPage";
import FirstPage from "@material-ui/icons/FirstPage";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  divStyles: {
    float: "right",
    margin: "8px 120px",
  },
  button: {
    margin: 1,
  },
});
function Paginator(props) {
  const {
    page,
    nextPage,
    prevPage,
    lastPage,
    handleFirstPage,
    handlePrev,
    handleNext,
    handleLastPage,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.divStyles}>
      <IconButton
        color="primary"
        className={classes.button}
        disabled={page === 1}
        onClick={handleFirstPage}
      >
        <FirstPage />
      </IconButton>
      <IconButton
        color="primary"
        className={classes.button}
        disabled={prevPage === null}
        onClick={handlePrev}
      >
        <Left />
      </IconButton>
      {page + " of " + lastPage}
      <IconButton
        color="primary"
        className={classes.button}
        disabled={nextPage === null}
        onClick={handleNext}
      >
        <Right />
      </IconButton>
      <IconButton
        color="primary"
        className={classes.button}
        disabled={page === lastPage}
        onClick={handleLastPage}
      >
        <LastPage />
      </IconButton>
    </div>
  );
}

export default Paginator;
