import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function Content(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [content, setContent] = useState({});
  const context = useContext(AppContext);
  const { changeLoading, changeRefreshing } = context;
  const [tab, setTab] = React.useState(0);
  const [tabs, setTabs] = React.useState([]);
  const [languageTab, setLanguageTab] = React.useState(0);
  const [languageTabs, setLanguageTabs] = React.useState([]);

  useEffect(() => {
    getContent();
  }, []);

  const getContent = () => {
    changeLoading(true);
    api
      .get("admin/app/content")
      .then((res) => {
        let data = res.data.data;
        setContent(data);
        setLanguageTabs(Object.keys(data));
        Object.keys(data).map((language) => {
          setTabs(Object.keys(data[language]));
        });
        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    changeRefreshing(true);
    let method = "POST";
    let url = "admin/app/content";
    const data = new FormData();
    data.append("content", JSON.stringify(content));
    api({ method, url, data })
      .then((res) => {
        changeRefreshing(false);
        getContent();
      })
      .catch((err) => {
        changeRefreshing(false);
        if (err.response) {
        }
      });
  };

  return (
    <div className={classes.root}>
      <Button
        disabled={isDisabled()}
        onClick={handleSubmit}
        color="primary"
        variant="contained"
      >
        save
      </Button>
      <Paper square style={{ maxWidth: "100vw", marginRight: 20 }}>
        <Tabs
          value={languageTab}
          onChange={(event, newTab) => {
            setLanguageTab(newTab);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          style={{ maxWidth: "90%" }}
        >
          {languageTabs.map((title, index) => (
            <Tab label={title} {...a11yProps(index)} />
          ))}
        </Tabs>
        <Tabs
          value={tab}
          onChange={(event, newTab) => {
            setTab(newTab);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          style={{ maxWidth: "90%" }}
        >
          {tabs.map((title, index) => (
            <Tab label={title} {...a11yProps(index)} />
          ))}
        </Tabs>
        {languageTabs.map((language, lIndex) => (
          <TabPanel value={languageTab} index={lIndex}>
            {tabs.map((title, index) => (
              <TabPanel value={tab} index={index}>
                <Grid
                  container
                  spacing={3}
                  style={{
                    marginBottom: "1.5vw",
                    marginTop: "0.3vw",
                  }}
                >
                  {content &&
                    typeof content[language][title] === "object" &&
                    Object.keys(content[language][title]).map((key) => (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label={key.toString().replaceAll("_", " ")}
                          className={classes.textField}
                          value={content[language][title][key]}
                          onChange={(event) => {
                            const clone = { ...content };
                            clone[language][title][key] = event.target.value;
                            setContent(clone);
                          }}
                          disabled={false}
                          style={{
                            textTransform: "uppercase",
                          }}
                          color="secondary"
                        />
                      </Grid>
                    ))}
                </Grid>
              </TabPanel>
            ))}
          </TabPanel>
        ))}
      </Paper>
    </div>
  );
}

export default Content;
