import React, { Component, useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import { Skeleton } from "@material-ui/lab";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import AddMediaModal from "./AddMediaModal";
import { api } from "../../constants/Constants";
import DirectoryContent from "./DirectoryContent";
import UtilityBar from "./UtilityBar";

export default function MediaLibrary(props) {
  const [currentDirectory, setCurrentDirectory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  const [openAddMediaModal, setOpenAddMediaModal] = useState(false);

  const getDirectory = (directory) => {
    setIsLoading(true);
    let path = directory ? `${directory}` : "root";
    const url = `admin/directories/${path}`;
    api
      .get(url)
      .then((res) => {
        setCurrentDirectory(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (props.open) getDirectory();
  }, [props.open]);

  const goBack = () => {
    if (currentDirectory.parent_id) {
      setSelected([]);
      getDirectory(currentDirectory.parent_id);
    }
  };

  const changeCurrentDirectory = (directory) => {
    setSelectedType(null);
    setSelected([]);
    getDirectory(directory.id);
  };

  const showMessage = (message, type) => {
    alert(message);
  };

  const handleFilesSelected = (files) => {
    if (!props.multiple && files.length > 1) {
      showMessage("Please choose one image only", "warning");
    } else {
      setSelected([]);
      setSelectedType(null);
      props.multiple
        ? props.handleFilesSelected(files)
        : props.handleFilesSelected(files[0]);
    }
  };

  const addRemoveSelected = (type, itemObj) => {
    const selectedClone = [...selected];
    if (selectedType === type) {
      const index = selectedClone.findIndex((item) => {
        return item.id === itemObj.id;
      });
      if (index === -1) {
        setSelected([...selectedClone, itemObj]);
      } else {
        selectedClone.splice(index, 1);
        if (selectedClone.length > 0) setSelected(selectedClone);
        else {
          setSelected(selectedClone);
          setSelectedType(null);
        }
      }
    } else if (!selectedType) {
      setSelected([...selectedClone, itemObj]);
      setSelectedType(type);
    }
  };

  const isSelected = (type, id) => {
    if (type === selectedType) {
      const found = selected.findIndex((item) => {
        return item.id === id;
      });
      return found !== -1;
    } else return false;
  };
  const deleteSelected = () => {
    const selectedClone = [...selected];
    if (selectedType === "directory") {
      let formData = new FormData();
      let ids = selectedClone.map((item) => item.id);
      formData.append("ids", ids);
      formData.append("_method", "delete");
      api
        .post("admin/directories", formData)
        .then((res) => {
          setSelectedType(null);
          setSelected([]);
          getDirectory(currentDirectory.id);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedType === "media") {
      let formData = new FormData();
      let ids = selected.map((item) => item.id);
      formData.append("ids", ids);
      formData.append("_method", "delete");
      api
        .post("admin/media/destroy", formData)
        .then((res) => {
          setSelectedType(null);
          setSelected([]);
          getDirectory(currentDirectory.id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const mangeFolder = (name, directory = null) => {
    setIsLoading(true);
    let path = "admin/directories";
    let formData = new FormData();
    formData.append("name", name);
    if (currentDirectory) {
      formData.append("parent_id", currentDirectory.id);
    }
    if (directory) {
      formData.append("_method", "put");
      path += "/" + directory.id;
    }
    api
      .post(path, formData)
      .then((res) => {
        getDirectory(currentDirectory.id);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleAddMediaModal = (value) => {
    setOpenAddMediaModal(value);
  };

  const addMedia = (files) => {
    setOpenAddMediaModal(false);
    setIsLoading(true);
    if (currentDirectory && files.length > 0) {
      let formData = new FormData();
      files.forEach((file) => {
        formData.append("uploads[]", file);
      });
      formData.append("directory_id", currentDirectory.id);
      api
        .post("admin/media/uploadMultiple", formData)
        .then((res) => {
          getDirectory(currentDirectory.id);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      alert("error");
    }
  };

  const handleClose = () => {
    setSelected([]);
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            aliginItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3>Media Library</h3>
          {selected.length > 0 && <p>{selected.length} selected </p>}
        </div>
      </DialogTitle>
      <DialogContent>
        <Container>
          {currentDirectory && (
            <React.Fragment>
              <Grid item xs={12}>
                <Button onClick={goBack} disabled={!currentDirectory.parent_id}>
                  <ArrowBackIcon />
                  <p>{currentDirectory ? currentDirectory.name : ""}</p>
                </Button>
              </Grid>
              {isLoading ? (
                <Grid container spacing={3}>
                  {Array.from(new Array(3)).map((item, index) => (
                    <Grid item xs={4} key={index}>
                      <Skeleton variant="rect" height="80px" />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <DirectoryContent
                  directory={currentDirectory}
                  changeCurrentDirectory={changeCurrentDirectory}
                  handleFilesSelected={handleFilesSelected}
                  addRemoveSelected={addRemoveSelected}
                  isSelected={isSelected}
                />
              )}
            </React.Fragment>
          )}
        </Container>
        <DialogActions>
          <AddMediaModal
            open={openAddMediaModal}
            handleOpen={handleAddMediaModal}
            handleSuccess={addMedia}
          />

          <UtilityBar
            selected={selected}
            selectedType={selectedType}
            deleteSelected={deleteSelected}
            mangeFolder={mangeFolder}
            handleAddMediaModal={handleAddMediaModal}
            handleFilesSelected={handleFilesSelected}
            hideSelectAll={false}
            multiple={props.multiple}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
