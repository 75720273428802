import React, { useState, useContext, Fragment, useEffect } from "react";
import ReferralsFormDialog from "./ReferralsFormDialog";
import ReferralsListing from "./ReferralsListing";
import FAB from "../../components/FAB";
import { api } from "../../constants/Constants";
import Paginator from "../../components/Paginator";
import AppContext from "../../context/AppContext";

const MODULE_URL = "/admin/referrals";

function Referrals(props) {
  const [items, setItems] = useState([]);
  const [itemDialog, setItemDialog] = useState({
    item: null,
    open: false,
  });
  const [sortDialog, setSortDialog] = useState({
    tree: [],
    open: false,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    nextPage: null,
    prevPage: null,
    lastPage: null,
  });
  const context = useContext(AppContext);
  const { changeLoading, showSnack, checkAuthorization, changeRefreshing } =
    context;

  useEffect(() => {
    getAll();
  }, [pagination.page]);

  const getAll = () => {
    changeLoading(true);
    api
      .get(MODULE_URL)
      .then((res) => {
        const response = res.data.data;
        setItems(response.data);
        setPagination({
          ...pagination,
          nextPage: response.next_page_url,
          prevPage: response.prev_page_url,
          lastPage: response.last_page,
        });
        changeLoading(false);
      })
      .catch(function (err) {
        console.log(err);
        changeLoading(false);
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  const handleNext = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const handlePrev = () => {
    setPagination({ ...pagination, page: pagination.page - 1 });
  };

  const handleLastPage = () => {
    setPagination({ ...pagination, page: pagination.lastPage });
  };

  const handleFirstPage = () => {
    setPagination({ ...pagination, page: 1 });
  };

  const handleClear = () => {
    setPagination({ page: 1, nextPage: null, prevPage: null, lastPage: null });
  };

  const fabCallback = () => {
    if (!checkAuthorization("create", "Referrals")) {
      showSnack("You don't have permission to create referrals", "error");
    } else {
      setItemDialog({ item: null, open: true });
    }
  };

  const handleCloseItemsFormDialog = () => {
    setItemDialog({ item: null, open: false });
  };

  const handleOpenEditFrom = (id) => {
    if (!checkAuthorization("update", "Referrals")) {
      showSnack("You don't have permission to edit referrals", "error");
    } else {
      setItemDialog({ item: id, open: true });
    }
  };

  return (
    <Fragment>
      <ReferralsListing data={items} handleOpenEditFrom={handleOpenEditFrom} />
      {items && items.length > 0 && (
        <Paginator
          {...pagination}
          handlePrev={handlePrev}
          handleNext={handleNext}
          handleLastPage={handleLastPage}
          handleFirstPage={handleFirstPage}
        />
      )}
      <ReferralsFormDialog
        handleClose={handleCloseItemsFormDialog}
        handleSuccess={() => {
          setItemDialog({ open: false, item: null });
          getAll();
        }}
        isOpen={itemDialog.open}
        id={itemDialog.item}
      />
      {items.length == 0 && <FAB callback={fabCallback} />}
    </Fragment>
  );
}

export default Referrals;
