import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FAB from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    color: "#fff",
  },
});

function FloatingActionButton(props) {
  const { classes, callback } = props;

  return (
    <div>
      <FAB
        // variant="contained"
        color="primary"
        aria-label="Add"
        className={classes.button}
        onClick={callback}
      >
        <AddIcon />
      </FAB>
    </div>
  );
}

FloatingActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

export default withStyles(styles)(FloatingActionButton);
