import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import TagsInput from "../../components/InputFields/TagsInput";
import ProjectInput from "../../components/InputFields/ProjectsInput";
import MultiSelectInput from "../../components/InputFields/MultiSelectInput";
import {
  isEmail,
  yupRequiredTranslatableString,
} from "../../helpers/Validators";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  name: "",
  price: "",
  is_active: 1,
  lead_time: "",
  finish_time: "",
  workers: 0,
  timeRanges: [],
  is_all: 1,
};

function ServicesItemsFormDialog(props) {
  const MODULE_URL = `admin/services_categories/${props.categoryID}/services_items`;
  const TIME_RANGES_URL = "/admin/time_ranges";

  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const [selectTimeOptions, setSelectTimeOptions] = useState([]);
  const { id, isOpen, handleSuccess } = props;
  const [timeRanges, setTimeRanges] = useState([]);

  useEffect(() => {
    if (isOpen) {
      getTimeRanges();
    }
    if (id && isOpen) {
      changeLoading(true);
      getItem();
    } else {
      setItem(empty);
      setSelectTimeOptions([]);
    }
  }, [id, isOpen]);

  const getItem = () => {
    api
      .get(`${MODULE_URL}/${id}`)
      .then((res) => {
        let tmp = res.data.data;
        setItem(tmp);
        let selectedIDs = tmp.timeRanges.map((item) => item.id);
        setSelectTimeOptions(selectedIDs);

        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };

  const getTimeRanges = () => {
    api
      .get(`${TIME_RANGES_URL}`)
      .then((res) => {
        const response = res.data.data;
        let data = [];
        if (response.data && response.data.length > 0) {
          response.data.map((item) => {
            if (item.is_active == 1) {
              item = {
                label: `${item.from} - ${item.to}`,
                value: item.id,
              };
              data.push(item);
            }
          });
        }
        setTimeRanges(data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              disabled={isDisabled()}
              onClick={handleSubmit}
              style={{
                color: headerTextColor,
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              name: yupRequiredTranslatableString(),
              price: Yup.number().integer(),
              lead_time: Yup.number().integer(),
              finish_time: Yup.number().integer(),
              workers: Yup.number().integer(),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = {
                ...values,
                price: values.price ? values.price : 0,
                lead_time: values.lead_time ? values.lead_time : 0,
                workers: values.workers ? values.workers : 0,
                finish_time: values.finish_time ? values.finish_time : 0,
                category_id: props.categoryID,
              };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue, values } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <SwitchInput
                      name="is_all"
                      label="Select All Projects"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    {values.is_all == 0 && (
                      <ProjectInput
                        setFieldValue={setFieldValue}
                        value={item.related_projects}
                      />
                    )}

                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="name"
                      label="Please enter item name"
                      grid={6}
                    />
                    <TextInput
                      name="price"
                      label="Please enter item price"
                      grid={6}
                    />
                    <TextInput
                      name="lead_time"
                      label="Please enter item lead time in minutes"
                      grid={6}
                    />
                    <TextInput
                      name="finish_time"
                      label="Please enter service time to finish in minutes"
                      grid={6}
                      type="number"
                    />
                    <TextInput
                      name="workers"
                      label="Please enter item service workers number"
                      grid={6}
                      type="number"
                    />
                    <TagsInput
                      name="line_items"
                      label="Please enter line items"
                      grid={6}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                    />
                    <Grid container spacing={12} xs={12}>
                      <MultiSelectInput
                        name="timeRanges"
                        label="Select time ranges"
                        grid={12}
                        options={timeRanges}
                        closeMenuOnSelect={false}
                        setFieldValue={setFieldValue}
                        value={selectTimeOptions}
                      />
                    </Grid>
                    <ImageInput
                      name="image"
                      label="Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default ServicesItemsFormDialog;
