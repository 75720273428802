import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import TextInput from "../../components/InputFields/TextInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Item from "./Item";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  title: "",
  is_active: 1,
  cover_image: [],
  gallery_image: [],
};

const ITEM_MODULE_URL = "/admin/project/constructionUpdateCategory/updateItems";

function ItemsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [items, setItems] = useState(props.items);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess, categoryID } = props;

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = () => {
    let data = [];
    if (items && items.length > 0) {
      items.map((item) => {
        item = {
          sort: item.sort,
          source: item.source,
          type: item.type.value == 0 ? 0 : item.type.value == 1 ? 1 : item.type,
        };
        data.push(item);
      });
    }
    props.syncItems(data);
  };
  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              disabled={isDisabled()}
              onClick={handleSubmit}
              style={{
                color: headerTextColor,
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <form onSubmit={handleSubmit}>
            <div className={classes.container}>
              <Button
                onClick={() => {
                  let itemsClone = [...items];
                  itemsClone.push({ sort: 0, source: "", type: 0 });
                  setItems(itemsClone);
                }}
              >
                Add Media
              </Button>
              {items &&
                items.length > 0 &&
                items.map((item, index) => (
                  <Item
                    item={item}
                    index={index}
                    updateItem={(updatedItem) => {
                      let itemsClone = [...items];
                      itemsClone[index] = updatedItem;
                      setItems(itemsClone);
                    }}
                    remove={() => {
                      let itemsClone = [...items];
                      itemsClone.splice(index, 1);
                      setItems(itemsClone);
                    }}
                  />
                ))}
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
}

export default ItemsFormDialog;
