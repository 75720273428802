import Admins from "../pages/admins/Admins";
import AdminRoles from "../pages/adminRoles/Roles";
import UserRoles from "../pages/userRoles/Roles";
import Events from "../pages/events/Events";
import PhoneDirectoryCategories from "../pages/phone_directory_categories/PhoneDirectoryCategories";
import PhoneDirectoryItems from "../pages/phone_directory_items/PhoneDirectoryItems";
import ActivityCategories from "../pages/activity_categories/ActivityCategories";
import ActivityItems from "../pages/activity_items/ActivityItems";

/* PLOP_INJECT_IMPORT */
import ReferralSubmissions from "../pages/ReferralSubmissions/ReferralSubmissions";
import Referrals from "../pages/Referrals/Referrals";
import Statuses from "../pages/Statuses/Statuses";
import PredeliveryFormSubmissions from "../pages/PredeliveryFormSubmissions/PredeliveryFormSubmissions";
import PredeliveryForms from "../pages/PredeliveryForms/PredeliveryForms";
import EventSubmissions from "../pages/EventSubmissions/EventSubmissions";
import SurveySubmissions from "../pages/SurveySubmissions/SurveySubmissions";
import Surveys from "../pages/Surveys/Surveys";
import FormFields from "../pages/FormFields/FormFields";
import Forms from "../pages/Forms/Forms";
import Founders from "../pages/Founders/Founders";
import AboutUs from "../pages/AboutUs/AboutUs";
import EventsAttendances from "../pages/EventsAttendances/EventsAttendances";
import InteractiveCommunications from "../pages/InteractiveCommunications/InteractiveCommunications";
import Gates from "../pages/Gates/Gates";
import EventsCategories from "../pages/EventsCategories/EventsCategories";
import HostEvents from "../pages/HostEvents/HostEvents";
import HostEventServices from "../pages/HostEventServices/HostEventServices";
import HostEventLocations from "../pages/HostEventLocations/HostEventLocations";
import EventTypes from "../pages/EventTypes/EventTypes";
import News from "../pages/News/News";
import NewsTags from "../pages/NewsTags/NewsTags";
import Properties from "../pages/Properties/Properties";
import UniqueFeatures from "../pages/UniqueFeatures/UniqueFeatures";
import Districts from "../pages/Districts/Districts";
import HomePageBanners from "../pages/HomePageBanners/HomePageBanners";
import ProjectTypes from "../pages/ProjectTypes/ProjectTypes";
import PropertyTypes from "../pages/PropertyTypes/PropertyTypes";
import RealEstateSubmissions from "../pages/RealEstateSubmissions/RealEstateSubmissions";
import Facilities from "../pages/Facilities/Facilities";
import AppSettings from "../pages/AppSettings/AppSettings";
import ImportedUsers from "../pages/ImportedUsers/ImportedUsers";
import Rentals from "../pages/Rentals/Rentals";
import Relations from "../pages/Relations/Relations";
import Menus from "../pages/Menus/Menus";
import Screens from "../pages/Screens/Screens";
import Users from "../pages/Users/Users";
import ContactUs from "../pages/ContactUs/ContactUs";
import Departments from "../pages/Departments/Departments";
import ServiceRequestRates from "../pages/ServiceRequestRates/ServiceRequestRates";
import ServicesRequests from "../pages/ServicesRequests/ServicesRequests";
import Settings from "../pages/Settings/Settings";
import Notifications from "../pages/Notifications/Notifications";
import TimeRanges from "../pages/TimeRanges/TimeRanges";
import ServicesItems from "../pages/ServicesItems/ServicesItems";
import Servicescategories from "../pages/Servicescategories/Servicescategories";
import Projects from "../pages/Projects/Projects";
import Guidelines from "../pages/Guidelines/Guidelines";
import ProjectConstructionUpdateCategories from "../pages/ProjectConstructionUpdateCategories/ProjectConstructionUpdateCategories";
import Dashboard from "../pages/Dashboard/Dashboard";
import GuestInvitations from "../pages/GuestInvitations/GuestInvitations";
import Content from "../pages/content/Contents";
import HomeWidgets from "../pages/HomeWidgets/HomeWidget";
import UsersTags from "../pages/UsersTags/UsersTags";

const routes = [
  {
    path: "/",
    component: () => <Dashboard />,
    permission: "view",
    module: null,
    exact: true,
  },
  {
    path: "/admins",
    component: () => <Admins />,
    permission: "view",
    exact: true,
    module: "Admins",
  },
  {
    path: "/roles/users",
    component: () => <UserRoles />,
    permission: "view",
    exact: true,
    module: "Roles",
  },
  {
    path: "/roles/admins",
    component: () => <AdminRoles />,
    permission: "view",
    exact: true,
    module: "Roles",
  },
  {
    path: "/eventsCategories/:id/events",
    component: (props) => <Events id={props.match.params.id} />,
    permission: "view",
    exact: true,
    module: "Events",
  },
  {
    path: "/phone_directory_categories",
    component: () => <PhoneDirectoryCategories />,
    permission: "view",
    exact: true,
    module: "PhoneDirectoryCategories",
  },
  {
    path: "/phone_directory_categories/:id/phone_directories_items",
    component: (props) => <PhoneDirectoryItems id={props.match.params.id} />,
    permission: "view",
    exact: true,
    module: "PhoneDirectoryItems",
  },
  {
    path: "/activity_categories",
    component: () => <ActivityCategories />,
    permission: "view",
    exact: true,
    module: "ActivityCategories",
  },
  {
    path: "/activity_categories/:id/sub_categories",
    component: (props) => <ActivityCategories id={props.match.params.id} />,
    permission: "view",
    exact: true,
    module: "ActivityCategories",
  },
  {
    path: "/activity_categories/:id/activity_items",
    component: (props) => <ActivityItems id={props.match.params.id} />,
    permission: "view",
    exact: true,
    module: "ActivityItems",
  },
  {
    path: "/activity_items",
    component: () => <ActivityItems />,
    permission: "view",
    exact: true,
    module: "ActivityItems",
  },
  {
    path: "/appSettings",
    component: (props) => <AppSettings />,
    permission: "view",
    module: "AppSettings",
    exact: true,
  },
  {
    path: "/facilities",
    component: (props) => <Facilities />,
    permission: "view",
    exact: true,
    module: "Facilities",
  },
  {
    path: "/imported_users",
    component: (props) => <ImportedUsers />,
    permission: "view",
    module: "ImportedUsers",
    exact: true,
  },
  {
    path: "/rentals",
    component: (props) => <Rentals />,
    permission: "view",
    module: "Rentals",
    exact: true,
  },
  {
    path: "/relations",
    component: (props) => <Relations />,
    permission: "view",
    exact: true,
    module: "Relations",
  },
  {
    path: "/menus",
    component: (props) => <Menus />,
    permission: "view",
    module: "Menus",
    exact: true,
  },
  {
    path: "/screens",
    component: (props) => <Screens />,
    permission: "view",
    exact: true,
    module: "Screens",
  },
  {
    path: "/users",
    component: (props) => <Users />,
    permission: "view",
    module: "Users",
    exact: true,
  },
  {
    path: "/service_request_rates",
    component: (props) => <ServiceRequestRates />,
    permission: "view",
    exact: true,
    module: "ServicesRequests",
  },
  {
    path: "/services_requests",
    component: (props) => <ServicesRequests />,
    permission: "view",
    exact: true,
    module: "ServicesRequests",
  },

  {
    path: "/settings",
    component: (props) => <Settings />,
    permission: "view",
    exact: true,
    module: "Settings",
  },
  {
    path: "/guidelines",
    component: (props) => <Guidelines />,
    permission: "view",
    exact: true,
    module: "Guidelines",
  },
  {
    path: "/contact_us",
    permission: "view",
    component: (props) => <ContactUs />,
    exact: true,
    module: "Contact_us",
  },
  {
    path: "/departments",
    permission: "view",
    component: (props) => <Departments />,
    exact: true,
  },

  {
    path: "/notifications",
    component: (props) => <Notifications />,
    permission: "view",
    exact: true,
    module: "notifications",
  },
  {
    path: "/services_categories/:id/services_items",
    component: (props) => <ServicesItems id={props.match.params.id} />,
    permission: "view",
    exact: true,
    module: "ServicesItems",
  },
  {
    path: "/services_categories/:id/sub_categories",
    component: (props) => <Servicescategories id={props.match.params.id} />,
    permission: "view",
    exact: true,
    module: "ServicesCategories",
  },
  {
    path: "/services_categories",
    component: (props) => <Servicescategories />,
    exact: true,
    permission: "view",
    module: "ServicesCategories",
  },
  {
    path: "/time_ranges",
    component: (props) => <TimeRanges />,
    exact: true,
    permission: "view",
    module: "TimeRanges",
  },
  {
    path: "/projects",
    component: (props) => <Projects />,
    permission: "view",
    exact: true,
    module: "Projects",
  },
  {
    path: "/districts/:id/projects",
    component: (props) => <Projects id={props.match.params.id} />,
    permission: "view",
    exact: true,
    module: "Projects",
  },
  {
    path: "/projects/:id/project-types/",
    component: (props) => <ProjectTypes id={props.match.params.id} />,
    permission: "view",
    exact: true,
    module: "ProjectTypes",
  },
  {
    path: "/projects/:id/properties",
    component: (props) => <Properties id={props.match.params.id} />,
    permission: "view",
    module: "Properties",
    exact: true,
  },
  {
    path: "/propertyTypes",
    component: (props) => <PropertyTypes />,
    permission: "view",
    exact: true,
    module: "PropertyTypes",
  },
  {
    path: "/form_submissions/byRelated/Project",
    component: (props) => <RealEstateSubmissions />,
    permission: "view",
    exact: true,
    module: "RealEstateSubmissions",
  },
  {
    path: "/constructionUpdateCategories/:id/projects/",
    component: (props) => (
      <ProjectConstructionUpdateCategories id={props.match.params.id} />
    ),
    permission: "view",
    exact: true,
    module: "Projects",
  },
  {
    path: "/guest_invitations",
    component: (props) => <GuestInvitations />,
    permission: "view",
    exact: true,
    module: "GuestInvitations",
  },
  {
    path: "/content",
    component: (props) => <Content />,
    permission: "view",
    exact: true,
    module: "AppSettings",
  },
  {
    path: "/home_widget",
    component: (props) => <HomeWidgets />,
    permission: "view",
    exact: true,
    module: "AppSettings",
  },
  {
    path: "/eventTypes",
    component: (props) => <EventTypes />,
    permission: "view",
    exact: true,
    module: "EventTypes",
  },
  {
    path: "/host_event_locations",
    component: (props) => <HostEventLocations />,
    exact: true,
    permission: "view",
    module: "HostEventLocations",
  },
  {
    path: "/host_event_services",
    component: (props) => <HostEventServices />,
    exact: true,
    permission: "view",
    module: "HostEventServices",
  },
  {
    path: "/host_events",
    component: (props) => <HostEvents />,
    exact: true,
    permission: "view",
    module: "HostEvents",
  },
  {
    path: "/newstags",
    component: (props) => <NewsTags />,
    permission: "view",
    exact: true,
    module: "Tags",
  },
  {
    path: "/news",
    component: (props) => <News />,
    permission: "view",
    exact: true,
    module: "News",
  },
  {
    path: "/eventsCategories",
    component: (props) => <EventsCategories />,
    exact: true,
    permission: "view",
    module: "EventsCategories",
  },
  {
    path: "/gates",
    component: (props) => <Gates />,
    exact: true,
    permission: "view",
    module: "Gates",
  },
  {
    path: "/interactive_communications",
    component: (props) => <InteractiveCommunications />,
    permission: "view",
    exact: true,
    module: "InteractiveCommunications",
  },
  {
    path: "/events_attendances",
    component: (props) => <EventsAttendances />,
    exact: true,
    permission: "view",
    module: "EventsAttendances",
  },
  {
    path: "/about_us",
    component: (props) => <AboutUs />,
    exact: true,
    permission: "view",
    module: "AboutUs",
  },
  {
    path: "/founders",
    component: (props) => <Founders />,
    exact: true,
    permission: "view",
    module: "Founders",
  },
  /* PLOP_INJECT_ROUTE_IMPORT */
  {
    path: "/form_submissions/byRelated/Referral/:id",
    component: (props) => <ReferralSubmissions id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "ReferralSubmissions",
  },
  {
    path: "/referrals",
    component: (props) => <Referrals />,
    permission: "view",
    exact: "exact",
    module: "Referrals",
  },
  {
    path: "/statuses",
    component: (props) => <Statuses />,
    permission: "view",
    exact: "exact",
    module: "Statuses",
  },
  {
    path: "/form_submissions/byRelated/PredeliveryForm/:id",
    component: (props) => (
      <PredeliveryFormSubmissions id={props.match.params.id} />
    ),
    permission: "view",
    exact: "exact",
    module: "PredeliveryFormSubmissions",
  },
  {
    path: "/predelivery_forms",
    component: (props) => <PredeliveryForms />,
    permission: "view",
    exact: "exact",
    module: "PredeliveryForms",
  },
  {
    path: "/form_submissions/byRelated/Event/:id",
    component: (props) => <EventSubmissions id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "EventSubmissions",
  },
  {
    path: "/form_submissions/byRelated/Survey/:id",
    component: (props) => <SurveySubmissions id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "SurveySubmissions",
  },
  {
    path: "/surveys",
    component: (props) => <Surveys />,
    permission: "view",
    module: "Surveys",
  },
  {
    path: "/forms",
    component: (props) => <Forms />,
    permission: "view",
    exact: "exact",
    module: "Forms",
  },
  {
    path: "/form_fields",
    component: (props) => <FormFields />,
    permission: "view",
    exact: "exact",
    module: "FormFields",
  },
  {
    path: "/forms/:id/form_fields",
    component: (props) => <FormFields id={props.match.params.id} />,
    permission: "view",
    exact: "exact",
    module: "FormFields",
  },
  {
    path: "/properties",
    component: (props) => <Properties />,
    permission: "view",
    exact: true,
    module: "Properties",
  },
  {
    path: "/unique_feature",
    component: (props) => <UniqueFeatures />,
    permission: "view",
    exact: true,
    module: "UniqueFeatures",
  },
  {
    path: "/projects/:id/unique_feature",
    component: (props) => <UniqueFeatures id={props.match.params.id} />,
    permission: "view",
    exact: true,
    module: "UniqueFeatures",
  },
  {
    path: "/districts",
    component: (props) => <Districts />,
    permission: "view",
    exact: true,
    module: "Districts",
  },
  {
    path: "/usertags",
    component: (props) => <UsersTags />,
    permission: "view",
    exact: true,
    module: "Tags",
  },
  /* PLOP_INJECT_ROUTE_IMPORT */
  {
    path: "/home_page_banners",
    component: (props) => <HomePageBanners />,
    exact: true,
    permission: "view",
    module: "HomePageBanners",
  },
];

export default routes;
