import React, { useState, useEffect, useContext } from "react";
import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import MultiSelectInput from "./MultiSelectInput";

export default function ProjectInput(props) {
  const context = useContext(AppContext);
  const { changeRefreshing } = context;
  const { setFieldValue, value } = props;
  const [projects, setProjects] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = () => {
    changeRefreshing(true);
    api
      .get("admin/projects/options")
      .then((res) => {
        let data = res.data.data.map((item) => {
          return {
            label:
              item.name + (item.district ? " - " + item.district.name : ""),
            value: item.id,
          };
        });
        // let dataInfo = data && [{ label: "Select All", value: "all" }, ...data];
        setProjects(data);
        changeRefreshing(false);
      })
      .catch((err) => {
        changeRefreshing(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (value && Array.isArray(value)) {
      setOptions(
        value.map((item) => {
          return item.id;
        })
      );
    }
  }, [value]);

  return (
    <MultiSelectInput
      name="relatedProjects"
      label="Select content projects"
      grid={12}
      options={projects}
      closeMenuOnSelect={false}
      setFieldValue={setFieldValue}
      value={options}
    />
  );
}
