import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import NavigationItems from "./NavigationItems";
import Switch from "@material-ui/core/Switch";
import styles from "./LayoutStyles";
import SnackBar from "../../components/SnackBar";
import AppContext from "../../context/AppContext";
import Loader from "../../components/Loader";
import RefreshLoader from "../../components/RefreshLoader";
import green from "@material-ui/core/colors/green";
import MediaLibrary from "../../components/media/MediaLibrary";

const generateTheme = (themeObject, dark) => {
  const primaryColor = themeObject.primary ?? "#fff";
  return createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          color: "red",
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {},
      },
      MuiPickersDay: {
        day: {},
        daySelected: {
          color: "white",
        },
        dayDisabled: {},
        current: {},
      },
      MuiPickersModal: {},
    },
    palette: {
      type: dark ? "dark" : "light",
      primary: {
        main: primaryColor,
        contrastText: "#fff",
      },
      secondary: {
        main: "#EEA5C7",
        contrastText: "#000",
      },
      success: {
        main: "#bac778",
        contrastText: "#000",
      },
    },
    typography: {
      useNextVariants: true,
    },
  });
};

function Layout(props) {
  const context = useContext(AppContext);
  const {
    themeObject,
    dark,
    config,
    switchDarkMode,
    snackBarObject,
    closeSnack,
    isRefreshing,
    isLoading,
    mediaLibrary,
    openMediaLibrary,
    closeMediaLibrary,
    triggerFileSelected,
  } = context;
  const [open, setOpen] = useState(false);
  const [theme, setTheme] = useState(generateTheme(themeObject, dark));
  const classes = styles();

  useEffect(() => {
    setTheme(generateTheme(themeObject, dark));
  }, [dark, themeObject]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!open}>
            <IconButton
              style={{
                color: headerTextColor,
              }}
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              style={{
                color: headerTextColor,
              }}
            >
              {config.name} Admin Tool
            </Typography>
            <div className={classes.switcher}>
              {/* <Typography variant="h6" color="inherit">
                Dark mode
              </Typography> */}
              <Switch
                checked={dark}
                onChange={(event) => switchDarkMode(event.target.checked)}
              />
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !open && classes.drawerPaperClose
            ),
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <NavigationItems />
          </List>
        </Drawer>
        <main
          className={classNames(
            classes.content,
            dark ? classes.darkContent : classes.content
          )}
        >
          <div className={classes.toolbar} />
          {props.children}
        </main>
        {isLoading && <Loader />}
        {isRefreshing && <RefreshLoader />}
        <SnackBar
          type={snackBarObject.type}
          open={snackBarObject.open}
          autoHideDuration={4000}
          message={snackBarObject.message}
          onClose={() => {
            closeSnack();
          }}
        />
        <MediaLibrary
          open={mediaLibrary.open}
          multiple={mediaLibrary.multiple}
          handleClose={closeMediaLibrary}
          handleFilesSelected={triggerFileSelected}
        />
      </div>
    </MuiThemeProvider>
  );
}

export default Layout;
