import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  TextField,
  DialogActions,
  Button,
  Slide,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import { api } from "../../constants/Constants";
import { withStyles } from "@material-ui/core/styles";
import AppContext from "../../context/AppContext";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  button: {},
});

const MODULE_URL = "/admin/users";

function UnitMates(props) {
  const context = useContext(AppContext);
  const { changeRefreshing } = context;
  const [units, setUnits] = useState([]);
  const { isVisible, id } = props;
  const classes = styles();

  useEffect(() => {
    if (isVisible && id) {
      getUnitMates(id);
    }
  }, [isVisible, id]);

  const getUnitMates = (user_id) => {
    changeRefreshing(true);
    api
      .get(MODULE_URL + `/unitMates/${user_id}`)
      .then((res) => {
        changeRefreshing(false);
        setUnits(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        changeRefreshing(false);
      });
  };
  const handleClose = () => {
    changeRefreshing(false);
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        open={isVisible}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <AppBar elevation={0} className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle2"
              color="inherit"
              className={classes.flex}
            >
              Units & Mates
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          {units &&
            units.length > 0 &&
            units.map((unit) => (
              <div>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    marginLeft: "1vw",
                    marginTop: "0.5vw",
                    marginBottom: "0.5vw",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  Unit Code:
                  <Typography
                    variant="h6"
                    component="h6"
                    color="primary"
                    style={{
                      marginLeft: "1vw",
                    }}
                  >
                    {unit.unit_code}
                  </Typography>
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    marginLeft: "1vw",
                    marginTop: "0.5vw",
                    marginBottom: "0.5vw",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  Project:
                  <Typography
                    variant="h6"
                    component="h6"
                    color="primary"
                    style={{
                      marginLeft: "1vw",
                    }}
                  >
                    {unit.project}
                  </Typography>
                </Typography>
                {unit.mates &&
                  unit.mates.length > 0 &&
                  unit.mates.map((mate) => (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Phone number</TableCell>
                          <TableCell>First name</TableCell>
                          <TableCell>Last name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{mate.id}</TableCell>
                          <TableCell>{mate.phone}</TableCell>
                          <TableCell>
                            {mate.first_name ? mate.first_name : "N/A"}
                          </TableCell>
                          <TableCell>
                            {mate.last_name ? mate.last_name : "N/A"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="textPrimary">
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(UnitMates);
