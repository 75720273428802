import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DropzoneComponent from "./DropzoneComponent";
import DialogContentText from "@material-ui/core/DialogContentText";

export default class AddDirectoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      sizes: [],
    };
  }

  addFiles = (files) => {
    let data = [];
    files.map((file) => {
      if (file.type == "application/pdf") {
        if (file.size > 10097152) {
          data.push(file.size);
        }
      } else {
        if (file.size > 2097152) {
          data.push(file.size);
        }
      }
    });
    this.setState({ sizes: data });
    this.setState({ files });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
            this.props.handleOpen(false);
            this.setState({ sizes: [] });
          }}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">Add Media</DialogTitle>
          {this.state.sizes && this.state.sizes.length > 0 && (
            <DialogContentText
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "15px",
              }}
            >
              Upload Image with 2M as MAX
            </DialogContentText>
          )}

          <DialogContent>
            <DropzoneComponent addFiles={this.addFiles} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.props.handleOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.props.handleSuccess(this.state.files);
              }}
              color="primary"
              disabled={this.state.sizes && this.state.sizes.length > 0}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
