import { isObject } from "./Validators";

export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export function areObjectsEqual(object1, object2) {
  if (!object1 || object2) {
    return object1 === object2;
  }
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !areObjectsEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

export function transformObjectToFromData(object) {
  let formData = new FormData();

  for (var key in object) {
    // remove empty keys
    if (object[key] === null || object[key] === "") {
      formData.append(key, "");
    } else formData.append(key, object[key]);
  }
  return formData;
}

export function isObjectEmpty(object) {
  return Object.keys(object).length === 0 && object.constructor === Object;
}

export function extractIds(array) {
  return array.map((item) => item.id);
}

export function arrayDiff(first, second) {
  return first.filter((item) => {
    const itemFoundInSecond = second.find((secondItem) => {
      return secondItem.id == item.id;
    });
    return itemFoundInSecond === undefined;
  });
}
