import React, { useEffect, useState, useContext } from "react";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import { jssPreset, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { object } from "yup/lib/locale";
import AppContext from "../../context/AppContext";

const useStyles = makeStyles({
  textField: {},
});

function TranslatableTextInput(props) {
  const [field, meta] = useField(props);
  const { value, onChange, onBlur, name } = field;
  const { error, touched } = meta;
  const { label, grid, type, disabled, rowsMax, setFieldValue, translations } =
    props;
  const classes = useStyles();
  const [translation, setTranslation] = useState({});
  const context = useContext(AppContext);
  const { languages } = context;

  useEffect(() => {
    if (translations) {
      setTranslation(translations[name]);
    }
  }, [translations]);

  useEffect(() => {
    setFieldValue(name, JSON.stringify(translation));
  }, [translation]);

  const onTranslationChange = (event, language) => {
    setTranslation({ ...translation, [language]: event.target.value });
  };

  return languages.map((language) => {
    return (
      <Grid item key={name} xs={grid}>
        <TextField
          fullWidth
          label={label + ` in ${language.name}`}
          name={name}
          id={name}
          className={classes.textField}
          value={translation[language.code] ? translation[language.code] : ''}
          onChange={(event) => onTranslationChange(event, language.code)}
          onBlur={onBlur}
          helperText={error && touched && error}
          error={error && touched}
          type={type}
          disabled={disabled}
          rowsMax={rowsMax}
        />
      </Grid>
    );
  });
}

export default TranslatableTextInput;
