import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, FieldArray, Field } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";
import MultiSelectInput from "../../components/InputFields/MultiSelectInput";

import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import Download from "@material-ui/icons/GetApp";
import SelectInput from "../../components/InputFields/SelectInput";
import {
  isEmail,
  yupLink,
  yupPhone,
  yupRequiredTranslatableString,
} from "../../helpers/Validators";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const MODULE_URL = "admin/projects";
const MODULE_Form_URL = "admin/forms/all_list";

function ProjectsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();
  const { id, isOpen, handleSuccess, districtId } = props;
  const empty = {
    name: "",
    intro: "",
    description: "",
    email: "",
    phone: "",
    location_link: "",
    website_link: "",
    is_active: 1,
    district_id: districtId ? parseInt(districtId) : 0,
    // cover_image: [],
    master_plan_image: [],
    gallery_image: [],
    propertyTypes: "",
    facilities: "",
    project_dmgian_id: "",
    form_id: "",
  };

  const [item, setItem] = useState(empty);
  const [districtsList, setDistrictsList] = useState([]);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const [selectIconOptions, setSelectIconOptions] = useState([]);
  const [size, setSize] = useState("");
  const [forms, setForms] = useState([]);
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setFacilities();
      setSelectIconOptions([]);
    }
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          if (tmp.email) {
            tmp.email = tmp.email.split(",");
          }
          let facilities =
            tmp.facilities && tmp.facilities !== ""
              ? tmp.facilities.map((facility) => {
                  return facility.id;
                })
              : [];
          setSelectIconOptions(facilities);
          setItem(tmp);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
    if (isOpen) {
      getDistricts();
      getForms();
      getFacilitiesList();
    }
  }, [id, isOpen]);

  const getDistricts = () => {
    changeLoading(true);
    api
      .get(`admin/districts/selection_list`)
      .then((res) => {
        let districts = res.data.data;
        districts = districts.map((district) => {
          return { label: district.name, value: district.id };
        });
        setDistrictsList(districts);
        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };
  const getForms = () => {
    api
      .get("admin/forms/all_list")

      .then((res) => {
        let forms = res.data.data;
        forms = forms.map((form) => {
          return { label: form.name, value: form.id };
        });
        let noneValue = {
          label: "None",
          value: "",
        };
        forms = [noneValue].concat(forms);
        setForms(forms);
        setForms(forms);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const getFacilitiesList = () => {
    api
      .get("admin/facilities/selection_list")
      .then((response) => {
        let facilities = response.data.data;
        facilities = facilities.map((facility) => {
          return { label: facility.name, value: facility.id };
        });
        setFacilities(facilities);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    if (size > 10485760) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };
  // const handleFileUpload = (e) => {
  //   let reader = new FileReader();
  //   let file = e.target.files[0];
  //   setSize(file.size);
  //   setFile(file);
  // };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        {...props}
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              disabled={isDisabled()}
              onClick={handleSubmit}
              style={{
                color: headerTextColor,
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              name: yupRequiredTranslatableString(),
              location_link: yupLink(),
              website_link: yupLink(),
              phone: yupPhone(),
              district_id: Yup.number()
                .required("Required field")
                .min(1, "Required field"),
              project_dmgian_id: Yup.number("Project id is number")
                .integer("Project id is integer")
                .positive("Project id is positive")
                .required("Project id is required"),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = {
                ...values,
                form_id: values.form_id ?? values.form_id,
              };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                    Object.keys(err.response.data).map((key) => {
                      showSnack(`${key}: ${err.response.data[key]}`, "error");
                    });
                  }
                });
            }}
          >
            {(props) => {
              const { errors, values, setFieldError, setFieldValue, touched } =
                props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <TranslatableTextInput
                      key="name"
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="name"
                      label="*Please enter project name"
                      grid={6}
                    />
                    <TextInput
                      name="project_dmgian_id"
                      label="Please enter project id"
                      grid={6}
                      setFieldValue={setFieldValue}
                    />
                    <TranslatableTextInput
                      key="intro"
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="intro"
                      label="Please enter subtitle"
                      grid={6}
                    />
                    {/* <TextInput
                      name="email"
                      label="Please enter email"
                      grid={6}
                    /> */}
                    <TextInput
                      name="phone"
                      label="Please enter phone"
                      grid={6}
                    />
                    <UrlInput
                      name="location_link"
                      label="Please enter the google maps location"
                      grid={6}
                    />
                    <UrlInput
                      name="website_link"
                      label="Please enter the website"
                      grid={6}
                    />
                    <SelectInput
                      name="district_id"
                      label="*Select district"
                      grid={6}
                      options={districtsList}
                      setFieldValue={setFieldValue}
                    />
                    <Grid container>
                      <FieldArray
                        name="email"
                        render={(arrayHelpers) => (
                          <>
                            {values.email &&
                              values.email.length > 0 &&
                              values.email.map((item, index) => (
                                <React.Fragment key={index}>
                                  <TextInput
                                    name={`email.${index}`}
                                    label="Please enter email"
                                    validate={(value) => {
                                      let error;

                                      if (!value) {
                                        error =
                                          "Please remove this row if not needed or write valid email format";
                                      } else if (!isEmail(value)) {
                                        error = "Invalid email address";
                                      }

                                      return error;
                                    }}
                                    grid={6}
                                  />
                                  <Grid item xs={6}>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      Remove email
                                    </Button>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add email
                            </Button>
                          </>
                        )}
                      />
                    </Grid>
                    <ImageInput
                      name="brochure"
                      label="brochure"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <SelectInput
                      name="form_id"
                      label="Select Form"
                      grid={6}
                      options={forms}
                    />
                    <TranslatableTextAreaInput
                      translations={item.allTranslations}
                      name="description"
                      label="Description"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    <MultiSelectInput
                      name="facilities"
                      label="Select Facilities"
                      grid={6}
                      options={facilities}
                      closeMenuOnSelect={false}
                      setFieldValue={setFieldValue}
                      value={selectIconOptions}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    <ImageInput
                      name="listing_image"
                      label="Listing image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    {/* <ImageInput
                      name="cover_image"
                      label="Cover image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    /> */}
                    <ImageInput
                      name="master_plan_image"
                      label="Master plan"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <GalleryInput
                      name="gallery"
                      label="Slider Images"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default ProjectsFormDialog;
