import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FolderIcon from "@material-ui/icons/Folder";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

const styles = (theme) => ({
  selected: {
    background: "rgba(0, 0, 0, 0.7)",
  },
  directoryButton: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.7)",
    },
  },
  card: {
    maxWidth: "100%",
    maxHeight: "120px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
});
class DirectoryContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { media, childs } = this.props.directory;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          {childs &&
            childs.map((directory, index) => (
              <Grid item xs={2} key={"directory_" + directory.id}>
                <Button
                  className={
                    this.props.isSelected("directory", directory.id)
                      ? classes.selected
                      : classes.directoryButton
                  }
                  onDoubleClick={() => {
                    this.props.changeCurrentDirectory(directory);
                  }}
                  onClick={() => {
                    this.props.addRemoveSelected("directory", directory);
                  }}
                >
                  <FolderIcon />
                  <p>{directory.name}</p>
                </Button>
              </Grid>
            ))}
        </Grid>
        <br />
        <Grid container spacing={1}>
          {media &&
            media.map((file) => (
              <Grid
                className={
                  this.props.isSelected("media", file.id)
                    ? classes.selected
                    : ""
                }
                item
                xs={2}
                key={"file_" + file.id}
                onClick={() => {
                  this.props.addRemoveSelected("media", file);
                }}
                onDoubleClick={() => {
                  this.props.handleFilesSelected &&
                    this.props.handleFilesSelected([file]);
                }}
              >
                <Card className={classes.card}>
                  <CardMedia
                    style={{ backgroundSize: "contain" }}
                    className={classes.media}
                    image={file.type == "document" ? "pdf.png" : file.url}
                    title={file.title}
                  />
                </Card>
              </Grid>
            ))}
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(DirectoryContent);
