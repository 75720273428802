import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import SelectInput from "../../components/InputFields/SelectInput";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  status: "",
  send_notification: 0,
};

const MODULE_URL = "admin/form_submissions/Survey";

function SurveySubmissionsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [statusesList, setStatusesList] = useState([]);

  useEffect(() => {
    if (isOpen) {
      getStatusesList();
    }
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let item = res.data.data;
          if (item.status && item.status.length > 0) {
            item.status = item.status[0].id;
          }
          setItem(item);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getStatusesList = () => {
    api
      .get("admin/statuses/selection_list")
      .then((response) => {
        let statuses = response.data.data;
        statuses = statuses.map((status) => {
          return { label: status.name, value: status.id };
        });
        setStatusesList(statuses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";

  const renderData = (data) => {
    return Object.keys(data).map((key) => {
      if (
        !Array.isArray(data[key]) &&
        data[key]["input_type"] !== 9 &&
        data[key] !== "" &&
        data[key]["fieldName"]
      ) {
        return (
          <>
            <Grid item xs={4}>
              <Typography variant="p">
                <b>{data[key]["fieldName"]}: </b>
                {data[key] && data[key]["label"]
                  ? data[key]["label"]
                  : typeof data[key]["value"] == "object"
                  ? data[key]["value"].join(" / ")
                  : data[key]["value"]}
              </Typography>
            </Grid>
          </>
        );
      } else if (
        item.form &&
        !Array.isArray(data[key]) &&
        data[key]["input_type"] !== 9 &&
        data[key] == "" &&
        !data[key]["fieldName"]
      ) {
        let filed = item.form.fields.find((field) => field.id == key);
        return (
          <>
            <Grid item xs={4}>
              <Typography variant="p">
                <b>{filed["name"]}: </b>
                {"N/A"}
              </Typography>
            </Grid>
          </>
        );
      } else if (
        !Array.isArray(data[key]) &&
        data[key]["input_type"] == 9 &&
        data[key]["number_of_uploads"] == 1 &&
        data[key] !== ""
      ) {
        return (
          <>
            <Grid item xs={4}>
              <Typography variant="p">
                <b>{data[key]["fieldName"]}: </b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img src={data[key]["value"]} />
            </Grid>
          </>
        );
      } else if (
        !Array.isArray(data[key]) &&
        data[key]["input_type"] == 9 &&
        data[key]["number_of_uploads"] > 1 &&
        data[key] !== ""
      ) {
        return (
          <>
            <Grid item xs={4}>
              <Typography variant="p">
                <b>{data[key]["fieldName"]}: </b>
              </Typography>
            </Grid>
            {data[key]["value"] &&
              data[key]["value"].map((item) => (
                <Grid item xs={6}>
                  <img src={item} />
                </Grid>
              ))}
          </>
        );
      }
    });
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              disabled={isDisabled()}
              onClick={handleSubmit}
              style={{
                color: headerTextColor,
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = {
                status: values.status,
                send_notification: values.send_notification,
              };

              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                    Object.keys(err.response.data).map((key) => {
                      showSnack(`${key}: ${err.response.data[key]}`, "error");
                    });
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <SelectInput
                      name="status"
                      label="Request status"
                      placeholder="Select request status"
                      grid={6}
                      options={statusesList}
                      // setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <SwitchInput
                    name="send_notification"
                    label="Send Notification"
                    grid={4}
                    setFieldValue={setFieldValue}
                  />
                </form>
              );
            }}
          </Formik>
          <Grid container spacing={1}>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <Typography variant="p">
                  <b>Survey Name:</b>
                  {item.survey && item.survey[0] && item.survey[0].name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <Typography variant="p">
                  <b>User Name:</b>
                  {item.user && item.user.full_name
                    ? item.user.full_name
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="p">
                  <b>User Phone:</b>
                  {item.user && item.user.phone ? item.user.phone : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="p">
                  <b>User Email:</b>
                  {item.user && item.user.email ? item.user.email : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            {item.unit && (
              <Grid item xs={4}>
                <Typography variant="p">
                  <b>Unit:</b>
                  {item.unit.unit_code}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="p">
                <b>Form Name:</b>
                {item.form ? item.form.name : "N/A"}
              </Typography>
            </Grid>
            {item.data &&
              Object.keys(item.data).length > 0 &&
              renderData(item.data)}
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

export default SurveySubmissionsFormDialog;
