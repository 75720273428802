import React from "react";
import AppContext from "./AppContext";
export default function WithAppContext(Component) {
  return function WrapperComponent(props) {
    return (
      <AppContext.Consumer>
        {(state) => {
          return <Component {...props} {...state} />;
        }}
      </AppContext.Consumer>
    );
  };
}
