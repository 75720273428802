import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { TimePicker } from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles({
  TimeInput: {},
});

const DATE_FORMAT = "HH:mm:ss";

function TimeInput(props) {
  const [field, meta] = useField(props);
  const { value, name, onBlur } = field;
  const [time, setTime] = useState(null);
  const { error, touched } = meta;
  const { label, grid, setFieldValue, setFieldError, format } = props;
  const classes = useStyles();

  useEffect(() => {
    // in case of initial value is object
    if (value && time === null) {
      setTime(moment(value, format ? format : DATE_FORMAT));
      setFieldValue(name, value);
    }
  }, [value]);

  useEffect(() => {
    if (time) {
      setFieldValue(name, time.clone().format(format ? format : DATE_FORMAT));
    }
  }, [time]);

  return (
    <Grid item xs={grid}>
      <InputLabel>{label}</InputLabel>
      <TimePicker
        name={name}
        value={time}
        fullWidth
        error={Boolean(error)}
        onChange={(date) => {
          setTime(date);
        }}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
      />
      <p className="MuiFormHelperText-root Mui-error">{touched && error}</p>
    </Grid>
  );
}

export default TimeInput;
