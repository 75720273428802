import React, { useState, Fragment, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MediaLibrary from "../../components/media/MediaLibrary";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import LinkIcon from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import GalleryInput from "../../components/InputFields/GalleryInput";
import Select from "react-select";
import ImageInput from "../../components/InputFields/ImageInput";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 400,
    marginRight: "1vw",
    marginBottom: "1vw",
    marginTop: "2vw",
    border: "1px solid rgb(55, 17, 109, 0.5)",

    // This line disable the blue border
    boxShadow: "none",
    borderRadius: 4,
    "&:hover": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
      borderRadius: 4,
    },
  }),
};
function Item(props) {
  const [uploadedImage, setUploadedImage] = useState("");
  const { classes, item, index } = props;
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [type, setType] = useState([]);
  useEffect(() => {
    let type = 0;
    if (item.type == 0) {
      type = { value: 0, label: "Image" };
      setType(type);
    } else if (item.type == 1) {
      type = { value: 1, label: "Video" };
      setType(type);
    }
  }, [item.type]);
  let mainImage = "temp.png";
  if (item.type == 0 || (selectedValue && selectedValue.value == 0)) {
    if (uploadedImage) mainImage = uploadedImage.url;
    else if (item.image) mainImage = item.image.url;
  }
  const selectOptions = [
    { value: 0, label: "Image" },
    { value: 1, label: "Video" },
  ];
  const handleSelect = (value) => {
    setSelectedValue(value);
    let itemClone = { ...item };
    setUploadedImage("");
    itemClone.source = "";
    itemClone.type = value;
    props.updateItem(itemClone);
  };
  let typeValue = "";
  if (selectedValue && (selectedValue.value == 0 || item.type == 0)) {
    typeValue = 0;
  } else if (selectedValue == null && item.type == 0) {
    typeValue = 0;
  } else if (selectedValue && (selectedValue.value == 1 || item.type == 1)) {
    typeValue = 1;
  } else if (selectedValue == null && item.type == 1) {
    typeValue = 1;
  }
  return (
    <Fragment>
      <Grid container spacing={3} className={classes.main}>
        <Grid item xs={12}>
          <Button
            color="danger"
            onClick={() => {
              props.remove();
            }}
          >
            Remove
          </Button>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth={true}
            type="number"
            min={0}
            label="Sort"
            value={item.sort ? item.sort : 0}
            onChange={(event) => {
              let itemClone = { ...item };
              itemClone.sort = event.target.value;
              props.updateItem(itemClone);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Type</FormLabel>
            <Grid container spacing={12} xs={12}>
              <Select
                placeholder="Please select parent"
                id="color"
                options={selectOptions}
                styles={customStyles}
                closeMenuOnSelect={false}
                value={selectedValue ? selectedValue : type}
                onChange={(value) => {
                  handleSelect(value);
                }}
              />
            </Grid>
          </FormControl>
        </Grid>
        {typeValue == 1 && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Link/Url"
              value={item.source}
              inputProps={{
                name: "source",
              }}
              onChange={(event) => {
                let itemClone = { ...item };
                itemClone.source = event.target.value;
                props.updateItem(itemClone);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      var win = window.open(item.source, "_blank");
                      win.focus();
                    }}
                  >
                    <LinkIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        )}
        {typeValue == 0 && (
          <Grid item xs={12}>
            <Button
              variant="extendedFab"
              component="span"
              className={classes.buttonUpload}
              color="secondary"
              onClick={() => {
                setMediaLibraryOpen(true);
              }}
            >
              Image *
            </Button>
            <img className={classes.preview} src={mainImage} alt="" />
          </Grid>
        )}
      </Grid>
      <MediaLibrary
        open={mediaLibraryOpen}
        handleClose={() => {
          setMediaLibraryOpen(false);
        }}
        handleFilesSelected={(file) => {
          setUploadedImage(file);
          let itemClone = { ...item };
          itemClone.source = file;
          props.updateItem(itemClone);
          setMediaLibraryOpen(false);
        }}
        multiple={false}
      />

      <hr />
    </Fragment>
  );
}

const styles = (theme) => ({
  main: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderStyle: "soild",
    borderWidth: 2,
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    paddingTop: "-100% !important",
    position: "relative !important",
    marginBottom: " -40px",
    marginLeft: "20px",
  },
});
export default withStyles(styles)(Item);
