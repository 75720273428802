import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// Steps
import ContentStep from "./steps/ContentStep";
import FiltrationStep from "./steps/FiltrationStep";
import ScheduleStep from "./steps/ScheduleStep";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Content", "Filters", "Schedule", "Summary"];
}

function getStepContent(step, props) {
  const { errors, setFieldError, setFieldValue, values } = props;
  switch (step) {
    case 0:
      return (
        <ContentStep
          errors={errors}
          values={values}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      );
    case 1:
      return (
        <FiltrationStep
          errors={errors}
          values={values}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      );
    case 2:
      return (
        <ScheduleStep
          errors={errors}
          values={values}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
        />
      );
    case 3:
      return "Summary";
    default:
      return "Unknown step";
  }
}

export default function NotificationsSteps(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const { values, handleSubmit } = props;
  const handleNext = () => {
    if (activeStep == 3) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const checkStepValidation = () => {
    const {
      type,
      title,
      body,
      cover_image,
      content,
      module_id,
      content_id,
      filtrationType,
      devices,
      genders,
      selectedProjects,
      selectedUserTypes,
      scheduleType,
      sendDataTime,
      selectedUsers,
    } = values;

    if (activeStep == 0) {
      if (type == 0) {
        return !title || !body || !cover_image || !content;
      } else if (type == 1) {
        return !title || !module_id || !body;
      }
    } else if (activeStep == 1) {
      if (filtrationType == 0) {
        // const selectedProjectsArray = JSON.parse(selectedProjects);
        const selectedUserTypesArray = JSON.parse(selectedUserTypes);
        if (
          // selectedProjectsArray.length > 0 &&
          selectedUserTypesArray.length > 0
        ) {
          return false;
        }
      } else if (filtrationType == 1) {
        return selectedUsers.length <= 0;
      }
    } else if (activeStep == 2) {
      if (scheduleType == 1) {
        return false;
      } else {
        return;
      }
    } else if (activeStep == 3) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep, props)}
            </Typography>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                disabled={checkStepValidation()}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
