import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  Fragment,
} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import SelectInput from "../../components/InputFields/SelectInput";
import TimeInput from "../../components/InputFields/TimeInput";
import Select from "react-select";
import ProjectInput from "../../components/InputFields/ProjectsInput";
import { yupLink } from "../../helpers/Validators";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  name: "",
  location: "",
  description: "",
  from: null,
  to: null,
  is_featured: 0,
  in_home: 0,
  is_active: 1,
  price: 0,
  points: 0,
  facebook: "",
  instagram: "",
  phone_number: "",
  maps_url: "",
  website: "",
  recurring_times: null,
  cover_image: [],
  listing_image: [],
  gallery_image: [],
  is_recurring: 0,
  recurring_type: null,
  recurring_days_from: null,
  recurring_days_to: null,
  recurring_time_from: null,
  recurring_time_to: null,
  gate_id: "",
  form_id: "",
};

const dayOfTheWeekOptions = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

function EventsFormDialog(props) {
  const MODULE_URL = `admin/eventsCategories/${props.categoryID}/events`;
  const MODULE_Form_URL = "admin/forms/all_list";

  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [gatesList, setGatesList] = useState([]);
  const [forms, setForms] = useState([]);

  useEffect(() => {
    if (isOpen) {
      getGatesList();
      getForms();
    }
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          setItem(res.data.data);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);
  const getGatesList = () => {
    api
      .get("admin/gates/all_list")
      .then((response) => {
        let gates = response.data.data;
        gates = gates.map((gate) => {
          return { label: gate.name, value: gate.id };
        });
        setGatesList(gates);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getForms = () => {
    api
      .get("admin/forms/all_list")

      .then((res) => {
        let forms = res.data.data;
        forms = forms.map((form) => {
          return { label: form.name, value: form.id };
        });
        let noneValue = {
          label: "None",
          value: "",
        };
        forms = [noneValue].concat(forms);
        setForms(forms);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle2"
              className={classes.flex}
              style={{
                color: headerTextColor,
              }}
            >
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              style={{
                color: headerTextColor,
              }}
              disabled={isDisabled()}
              onClick={handleSubmit}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(256, "Must be 256 characters or less")
                .required("Required"),
              location: Yup.string()
                .max(256, "Must be 256 characters or less")
                .required("Required"),
              // website: yupLink().nullable(),
              facebook: yupLink().nullable(),
              instagram: yupLink().nullable(),
              maps_url: yupLink().nullable(),
              points: Yup.number(),
              gate_id: Yup.number(),
              // phone_number: Yup.string()
              //   .max(256, "Must be 256 characters or less")
              //   .required("Required"),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = {
                ...values,
                category_id: props.categoryID,
                form_id: values.form_id ?? values.form_id,
              };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              if (input.is_recurring == 1) {
                delete input["from"];
                delete input["to"];
              } else {
                delete input["recurring_type"];
                delete input["recurring_days_from"];
                delete input["recurring_days_to"];
                delete input["recurring_time_from"];
                delete input["recurring_time_to"];
              }

              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                    Object.keys(err.response.data).map((key) => {
                      showSnack(`${key}: ${err.response.data[key]}`, "error");
                    });
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue, values } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <ProjectInput
                      setFieldValue={setFieldValue}
                      value={item.related_projects}
                    />
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="name"
                      label="Please enter event name"
                      grid={6}
                    />
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="location"
                      label="Please enter event location"
                      grid={6}
                    />
                    <TextInput
                      name="price"
                      label="Please enter event ticket price(s)"
                      grid={6}
                    />
                    <TextInput
                      name="points"
                      label="Please enter Points"
                      grid={6}
                    />
                    {/* <TextInput
                      name="phone_number"
                      label="Please enter event phone"
                      grid={6}
                    /> */}
                    {/* <UrlInput
                      name="website"
                      label="Please enter the event Website/Page"
                      grid={6}
                    /> */}
                    <SelectInput
                      name="gate_id"
                      label="Select Gate"
                      grid={6}
                      options={gatesList}
                    />
                    <UrlInput
                      name="maps_url"
                      label="Please enter the event google maps location"
                      grid={6}
                    />
                    <UrlInput
                      name="facebook"
                      label="Please enter the event Facebook page"
                      grid={6}
                    />
                    <UrlInput
                      name="instagram"
                      label="Please enter the event Instagram page"
                      grid={6}
                    />
                    {values.is_recurring != 1 && (
                      <Fragment>
                        <DateTimeInput
                          name="from"
                          label="Start at:"
                          grid={6}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          errors={errors}
                        />
                        <DateTimeInput
                          name="to"
                          label="Ends at:"
                          grid={6}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          errors={errors}
                        />
                      </Fragment>
                    )}
                    <TranslatableTextAreaInput
                      translations={item.allTranslations}
                      name="description"
                      label="Description"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    <SelectInput
                      name="form_id"
                      label="Select Form"
                      grid={6}
                      options={forms}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    <SwitchInput
                      name="in_home"
                      label="In Home"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    {/* <SwitchInput
                      name="is_featured"
                      label="Is featured"
                      grid={4}
                      setFieldValue={setFieldValue}
                    /> */}
                    {/* <SwitchInput
                      name="is_recurring"
                      label="Is recurring"
                      grid={4}
                      setFieldValue={setFieldValue}
                    /> */}
                    {/* {values.is_recurring == 1 && (
                      <Fragment>
                        <SelectInput
                          name="recurring_type"
                          label="Select recurring event type"
                          grid={6}
                          options={[
                            { label: "Weekly", value: 0 },
                            { label: "Monthly", value: 1 },
                          ]}
                        />
                        {values.recurring_type == 0 && (
                          <Fragment>
                            <SelectInput
                              name="recurring_days_from"
                              label="Select starting day of the week"
                              grid={6}
                              options={dayOfTheWeekOptions}
                            />
                            <TimeInput
                              name="recurring_time_from"
                              label="From:"
                              grid={6}
                              setFieldValue={setFieldValue}
                              setFieldError={setFieldError}
                            />
                            <SelectInput
                              name="recurring_days_to"
                              label="Select end day of the week"
                              grid={6}
                              options={dayOfTheWeekOptions}
                            />
                            <TimeInput
                              name="recurring_time_to"
                              label="To:"
                              grid={6}
                              setFieldValue={setFieldValue}
                              setFieldError={setFieldError}
                              errors={errors}
                              format="h:mm a"
                            />
                          </Fragment>
                        )}
                        {values.recurring_type == 1 && (
                          <Fragment>
                            <TextInput
                              name="recurring_days_from"
                              label="Please enter start day of month, write -1 for end of month"
                              grid={6}
                              type="number"
                            />
                            <TimeInput
                              name="recurring_time_from"
                              label="From:"
                              grid={6}
                              setFieldValue={setFieldValue}
                              setFieldError={setFieldError}
                            />
                            <TextInput
                              name="recurring_days_to"
                              label="Please enter start day of month, write -1 for end of month"
                              grid={6}
                              type="number"
                            />
                            <TimeInput
                              name="recurring_time_to"
                              label="To:"
                              grid={6}
                              setFieldValue={setFieldValue}
                              setFieldError={setFieldError}
                              errors={errors}
                              format="h:mm a"
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    )} */}
                    <ImageInput
                      name="cover_image"
                      label="Cover Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <ImageInput
                      name="listing_image"
                      label="Listing Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    {/* 
                    <GalleryInput
                      name="gallery"
                      label="Gallery Images"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    /> */}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default EventsFormDialog;
