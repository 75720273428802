import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  container: {
    height: "200%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 9999,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#DCDCDC",
    opacity: 0.5,
    textAlign: "center",
  },
  loader: {
    // margin: "auto",
    marginTop: "50vh",
  },
});

function SimpleLoader(props) {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <CircularProgress className={classes.loader} color="primary" size={80} />
    </div>
  );
}

SimpleLoader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleLoader);
