import React, { useContext, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Filters from "../../components/Filters";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import moment from "moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import AppContext from "../../context/AppContext";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PublishIcon from "@material-ui/icons/Publish";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const MODULE_FILTERS_URL = "/admin/users/filters";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
  root2: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root3: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
  },
});

const useStyles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  tableCellStyles: {
    padding: "15px",
  },
  resendButton: {
    marginLeft: theme.spacing(2),
  },
});

function EnhancedTableToolbar(props) {
  const {
    classes,
    handleFilter,
    numSelected,
    handleExport,
    handleImport,
    selected,
  } = props;
  return (
    <div>
      <Toolbar className={classNames(classes.root)}>
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            Users
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Tooltip title="Filters">
            <Filters handleFilter={handleFilter} url={MODULE_FILTERS_URL} />
          </Tooltip>
          <Tooltip title="export all">
            <IconButton
              aria-label="export all"
              onClick={() => props.handleExport(selected)}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          {numSelected > 0 && (
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                onClick={() => props.handleDeleteButton(selected)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    </div>
  );
}
EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

function UsersListing(props) {
  const [selected, setSelected] = useState([]);
  const [sorting, setSorting] = useState({
    order: "desc",
    orderBy: "id",
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    items: [],
  });

  const context = useContext(AppContext);
  const { showSnack, checkAuthorization } = context;

  const {
    handleDelete,
    classes,
    data,
    handleOpenEditFrom,
    handleFilter,
    handleExport,
    handleImport,
    handleOpenSorting,
    handleStatusChange,
    showUserUnits,
    showUserDevices,
    showUserTags,
  } = props;
  const handleRequestSort = (event, property) => {
    const tempOrderBy = property;
    let tempOrder = "desc";
    const { orderBy, order } = sorting;
    if (orderBy === property && order === "desc") {
      tempOrder = "asc";
    }
    setSorting({ order: tempOrder, orderBy: tempOrderBy });
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      setSelected(props.data.map((n) => n.id));
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const tempSelected = [...selected];
    const selectedIndex = tempSelected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(tempSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(tempSelected.slice(1));
    } else if (selectedIndex === tempSelected.length - 1) {
      newSelected = newSelected.concat(tempSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        tempSelected.slice(0, selectedIndex),
        tempSelected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleDeleteButton = (toBeDeleted) => {
    if (!checkAuthorization("delete", "Users")) {
      showSnack("You don't have permission to delete users", "error");
    } else {
      handleOpenConfirmation(toBeDeleted);
    }
  };

  const handleOpenConfirmation = (toBeDeleted) => {
    setDeleteDialog({ open: true, items: toBeDeleted });
  };

  const handleCloseConfirmation = () => {
    setDeleteDialog({ open: false, items: [] });
  };

  const deleteItems = () => {
    let toBeDeleted = deleteDialog.items;
    handleCloseConfirmation();
    handleDelete(toBeDeleted);
    setSelected([]);
  };

  const checkSelected = (id) => {
    return selected.indexOf(id) !== -1;
  };

  const numSelected = selected.length;
  const rowCount = data.length;
  return (
    <Paper className={classes.root}>
      <DeleteConfirmationDialog
        isOpen={deleteDialog.open}
        id={deleteDialog.items}
        handleCloseDeleteConfirmationDialog={handleCloseConfirmation}
        title="Deleting"
        text="Are you sure you want to perform this action?"
        handleConfirmDelete={deleteItems}
      />
      <EnhancedTableToolbar
        handleFilter={handleFilter}
        data={data}
        numSelected={numSelected}
        selected={selected}
        handleDeleteButton={handleDeleteButton}
        handleExport={handleExport}
        handleImport={handleImport}
        handleOpenSorting={handleOpenSorting}
      />
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={handleSelectAllClick}
              />
            </TableCell>
            {/* <TableCell>ID</TableCell> */}
            <TableCell>Phone</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Updated at</TableCell>
            <TableCell>Activity</TableCell>
            <TableCell>Controls</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data !== null &&
            data.map((row) => {
              const isSelected = checkSelected(row.id);
              return (
                <TableRow
                  key={row.id}
                  hover
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  selected={isSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      onClick={(event) => handleClick(event, row.id)}
                    />
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell> */}
                  <TableCell component="th" scope="row">
                    {row.phone}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.first_name ? row.first_name : "N/A"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.last_name ? row.last_name : "N/A"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(row.created_at).format(DATE_FORMAT)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(row.updated_at).format(DATE_FORMAT)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Switch
                      checked={row.is_active == 1}
                      onChange={(event, checked) => {
                        handleStatusChange(row.id, checked ? 1 : 0);
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Tooltip title="Units">
                      <IconButton
                        className={classes.button}
                        aria-label="Units"
                        onClick={() => showUserUnits(row.id)}
                        disabled={row.is_guest}
                      >
                        <StoreMallDirectoryIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Devices">
                      <IconButton
                        className={classes.button}
                        aria-label="Devices"
                        onClick={() => showUserDevices(row.id)}
                        disabled={!row.has_devices}
                      >
                        <PhoneIphoneIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Interests">
                      <IconButton
                        className={classes.button}
                        aria-label="Interests"
                        onClick={() => showUserTags(row.id)}
                        disabled={!row.has_tags}
                      >
                        <PhoneIphoneIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton
                        className={classes.button}
                        aria-label="Delete"
                        onClick={() => handleOpenEditFrom(row.id)}
                      >
                        <EditIcon color="action" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        className={classes.button}
                        aria-label="Delete"
                        onClick={() => handleDeleteButton(row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withStyles(useStyles)(UsersListing);
