import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  TextField,
  DialogActions,
  Button,
  Slide,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { api } from "../../constants/Constants";
import RefreshLoader from "../../components/RefreshLoader";
import { Formik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core/styles";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import ImageInput from "../../components/InputFields/ImageInput";
import DialogTitle from "@material-ui/core/DialogTitle";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  button: {},
});

function EditNotification(props) {
  const [isLoading, setIsLoading] = useState();
  const [item, setItem] = useState({});
  const { isVisible, notification } = props;
  const formRef = useRef();
  const classes = styles();

  useEffect(() => {
    const notification = props.notification;
    if (notification) {
      setIsLoading(true);
      api
        .get(`admin/notifications/${notification}`)
        .then((res) => {
          let data = res.data.data;
          setItem({
            title: data.title,
            content: data.content,
            cover_image: data.cover_image,
            allTranslations: data.allTranslations,
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [isVisible, notification]);

  const handleSuccess = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const handleClose = () => {
    setIsLoading(false);
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        open={isVisible}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        {/* <AppBar elevation={0} className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="subtitle2"
              color="inherit"
              className={classes.flex}
            >
              Edit Notification
            </Typography>
          </Toolbar>
        </AppBar> */}
        <DialogTitle id="customized-dialog-title">
          Edit Notification
        </DialogTitle>

        <DialogContent dividers>
          {isLoading ? (
            <RefreshLoader />
          ) : (
            <div className={classes.container}>
              <Typography
                variant="subtitle1"
                color="error"
                style={{ paddingBottom: 35 }}
              >
                * Please note: editing notification will not send it again it
                will change title, body and image of notification appears in the
                app
              </Typography>
              <Formik
                enableReinitialize={true}
                innerRef={formRef}
                initialValues={item}
                // validationSchema={Yup.object({
                //   title: Yup.string()
                //     .max(256, "Must be 256 characters or less")
                //     .required("Required"),
                //   content: Yup.string().required("Required"),
                // })}
                onSubmit={async (values, { setSubmitting }) => {
                  await new Promise((r) => setTimeout(r, 500));
                  setSubmitting(true);
                  setIsLoading(true);
                  const { title, content, cover_image } = values;
                  let formData = new FormData();
                  if (title) {
                    formData.append("title", title);
                  }
                  if (content) {
                    formData.append("content", content);
                  }
                  if (cover_image) {
                    formData.append("cover_image", cover_image);
                  }
                  formData.append("_method", "put");
                  api
                    .post(`admin/notifications/${notification}`, formData)
                    .then((res) => {
                      setIsLoading(false);
                      props.handleEditSuccess();
                    })
                    .catch((err) => {
                      console.log(err);
                      if (err.response) {
                        const { status, data } = err.response;
                      }
                      setIsLoading(false);
                    });
                }}
              >
                {(props) => {
                  const { errors, setFieldError, setFieldValue } = props;
                  return (
                    <form>
                      <Grid container spacing={3}>
                        <TranslatableTextInput
                          name="title"
                          label="Please enter notification title"
                          grid={12}
                          setFieldValue={setFieldValue}
                          translations={item.allTranslations}
                        />
                        <ImageInput
                          name="cover_image"
                          label="Choose notification image"
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          errors={errors}
                          grid={12}
                        />
                        <TranslatableTextAreaInput
                          name="content"
                          label="Please enter notification content"
                          grid={12}
                          setFieldValue={setFieldValue}
                          translations={item.allTranslations}
                        />
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="textPrimary">
            Cancel
          </Button>
          <Button onClick={handleSuccess} color="textPrimary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* {isLoading && <RefreshLoader />} */}
    </div>
  );
}

export default withStyles(styles)(EditNotification);
