import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { debounce } from "../helpers/General";
import axios from "axios";
import { api, API_URL, headers } from "../constants/Constants";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const FilterAndSelectUsers = (props) => {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(props.users);
  const [searching, setSearching] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = debounce((value) => {
    setSearching(true);
    api
      .get(`admin/users/search?query=${value}`)
      .then((response) => {
        setUsers(response.data.data);
        setSearching(false);
      })
      .catch((err) => {
        setSearching(false);
        setUsers([]);
      });
  }, 500);

  const removeSelectedUser = (user) => {
    const selectedUsersCopy = [...selectedUsers];
    const index = selectedUsersCopy.findIndex((item) => item.id == user.id);
    selectedUsersCopy.splice(index, 1);
    setSelectedUsers(selectedUsersCopy);
    props.updateUsers(selectedUsersCopy);
  };

  const addUser = (user) => {
    const selectedUsersCopy = [...selectedUsers];
    const index = selectedUsersCopy.findIndex((item) => item.id == user.id);
    if (index === -1) {
      selectedUsersCopy.push(user);
    }
    setSelectedUsers(selectedUsersCopy);
    props.updateUsers(selectedUsersCopy);
  };

  const isSelected = (user) => {
    return selectedUsers.find((item) => item.id === user.id);
  };
  return (
    <div style={{ paddingBottom: 25, marginTop: 10, marginBottom: 10 }}>
      <Button color="primary" variant="contained" onClick={handleClickOpen}>
        Add/Mange selected users
      </Button>
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
        <DialogTitle onClose={handleClose}>Select Users</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <TextField
                label="Search"
                fullWidth
                onChange={(event) => handleSearchChange(event.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={2}>
              {searching && <CircularProgress />}
            </Grid>
          </Grid>

          {selectedUsers.length > 0 && (
            <Paper>
              {selectedUsers.map((user, index) => {
                return (
                  <Chip
                    variant="outlined"
                    label={user.email}
                    clickable
                    color="secondary"
                    onDelete={() => removeSelectedUser(index)}
                  />
                );
              })}
            </Paper>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Add/Remove</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Phone number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow>
                  <TableCell>
                    {isSelected(user) ? (
                      <IconButton
                        aria-label="Add"
                        onClick={() => removeSelectedUser(user)}
                        color="success"
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="Remove"
                        color="danger"
                        onClick={() => addUser(user)}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilterAndSelectUsers;
