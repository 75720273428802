import React from "react";
import { useField } from "formik";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  selectInput: {},
});

function SelectInput(props) {
  const [field, meta] = useField(props);
  const { error, touched } = meta;
  const { value, onChange, name, onBlur } = field;
  const {
    label,
    grid,
    options,
    closeMenuOnSelect,
    components,
    setFieldValue,
    valueKey,
    labelKey,
    onChangeHook,
  } = props;
  const classes = useStyles();

  return (
    <Grid item xs={grid}>
      <InputLabel style={{ marginBottom: "1vw" }}>{label}</InputLabel>
      <Select
        className={classes.selectInput}
        fullWidth={true}
        value={value}
        onChange={(e) => {
          onChange(e);
          onChangeHook && onChangeHook(e);
        }}
        helperText={touched && error}
        error={error && touched}
        disabled={props.disabled}
        inputProps={{
          name: name,
        }}
      >
        {options.map((option) => {
          let text = "";
          if (Array.isArray(labelKey)) {
            labelKey.map((key) => {
              text = `${text} ${option[key]} |`;
            });
          } else {
            text = option[labelKey];
          }
          return (
            <MenuItem
              value={option[valueKey]}
              key={name + "_option_" + option[valueKey]}
            >
              {text}
            </MenuItem>
          );
        })}
      </Select>
      <p className="MuiFormHelperText-root Mui-error" id="name-helper-text">
        {touched && error}
      </p>
    </Grid>
  );
}

SelectInput.defaultProps = {
  valueKey: "value",
  labelKey: "label",
};

export default SelectInput;
