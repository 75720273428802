import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { api } from "../../constants/Constants";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tree: { height: "100%" },
}));

function Fields(props) {
  const { open, handleClose, handleSuccess, module_id } = props;
  const classes = useStyles();
  const [moduleFields, setModuleFields] = useState([]);
  const [fields, setFields] = useState([]);

  const getModuleFields = () => {
    api
      .get(`admin/app_modules/fields/${module_id}`)
      .then((response) => {
        let data = response.data.data;
        setModuleFields(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (props.module_id && props.open) {
      getModuleFields();
      setFields(JSON.parse(props.fields));
    } else if (!props.open) {
      setModuleFields([]);
      setFields([]);
    }
  }, [props.module_id, props.open]);

  const isChecked = (moduleField) => {
    return fields && fields.length > 0 && fields.indexOf(moduleField) != -1;
  };
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Choose Fields</DialogTitle>
      <DialogContent dividers>
        <div className={classes.root}>
          <div className={classes.tree}>
            <Grid container spacing={3}>
              {moduleFields &&
                Object.keys(moduleFields).length > 0 &&
                Object.values(moduleFields).map((moduleField) => {
                  return (
                    <Grid item xs={3}>
                      <FormControlLabel
                        className={classes.checkBoxInput}
                        checked={isChecked(moduleField)}
                        control={
                          <Checkbox
                            onChange={(event) => {
                              let data = [...fields];
                              if (isChecked(moduleField)) {
                                data.splice(data.indexOf(moduleField), 1);
                              } else {
                                data.push(moduleField);
                              }
                              setFields(data);
                            }}
                            color="primary"
                          />
                        }
                        label={moduleField.toString().replaceAll("_", " ")}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleSuccess(fields);
          }}
          color="primary"
        >
          Save
        </Button>
        <Button onClick={handleClose} color="action">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Fields;
