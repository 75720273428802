import React, { useState, useContext, Fragment, useEffect } from "react";
import ProjectsFormDialog from "./ProjectsFormDialog";
import ProjectsListing from "./ProjectsListing";
import ProjectsSorting from "./ProjectsSorting";
import FAB from "../../components/FAB";
import { api } from "../../constants/Constants";
import Paginator from "../../components/Paginator";
import AppContext from "../../context/AppContext";

const MODULE_URL = "admin/projects";
const Types_URL = "admin/propertyTypes";

function Projects(props) {
  const [items, setItems] = useState([]);
  const districtId = props.id;

  const [filters, setFilters] = useState(null);
  const [itemDialog, setItemDialog] = useState({
    item: null,
    open: false,
  });
  const [sortDialog, setSortDialog] = useState({
    tree: [],
    open: false,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    nextPage: null,
    prevPage: null,
    lastPage: null,
  });
  const context = useContext(AppContext);
  const { changeLoading, showSnack, checkAuthorization, changeRefreshing } =
    context;

  useEffect(() => {
    if (filters != null) getAll();
  }, [filters]);

  useEffect(() => {
    getAll();
  }, [pagination.page]);

  const getAll = () => {
    changeLoading(true);
    const { page } = pagination;

    let filters_string = JSON.stringify(filters);
    let url = "";
    if (filters && filters.length > 0) {
      if (districtId) {
        url = `${MODULE_URL}/${districtId}/byDistrict?page=${page}&&filters=${filters_string}`;
      } else {
        url = `${MODULE_URL}?page=${page}&&filters=${filters_string}`;
      }
    } else {
      if (districtId) {
        url = `${MODULE_URL}/${districtId}/byDistrict?page=${page}`;
      } else {
        url = `${MODULE_URL}?page=${page}`;
      }
    }
    api
      .get(url)
      .then((res) => {
        const response = res.data.data;
        setItems(response.data);
        setPagination({
          ...pagination,
          nextPage: response.next_page_url,
          prevPage: response.prev_page_url,
          lastPage: response.last_page,
        });
        changeLoading(false);
      })
      .catch(function (err) {
        console.log(err);
        changeLoading(false);
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  const handleNext = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const handlePrev = () => {
    setPagination({ ...pagination, page: pagination.page - 1 });
  };

  const handleLastPage = () => {
    setPagination({ ...pagination, page: pagination.lastPage });
  };

  const handleFirstPage = () => {
    setPagination({ ...pagination, page: 1 });
  };

  const handleClear = () => {
    setPagination({ page: 1, nextPage: null, prevPage: null, lastPage: null });
  };

  const handleFilter = (filters) => {
    setFilters(filters);
  };

  const fabCallback = () => {
    if (!checkAuthorization("create", "Projects")) {
      showSnack("You don't have permission to create projects", "error");
    } else {
      setItemDialog({ item: null, open: true });
    }
  };

  const handleCloseItemsFormDialog = () => {
    setItemDialog({ item: null, open: false });
  };

  const handleOpenEditFrom = (id) => {
    if (!checkAuthorization("update", "Projects")) {
      showSnack("You don't have permission to edit projects", "error");
    } else {
      setItemDialog({ item: id, open: true });
    }
  };

  const handleDelete = (toBeDeleted) => {
    changeRefreshing(true);
    const data = {};
    let url = `${MODULE_URL}/`;
    const method = "POST";
    if (toBeDeleted instanceof Array) {
      url += "destroyMany";
      data.ids = toBeDeleted;
    } else {
      url += toBeDeleted.toString();
    }
    data._method = "DELETE";
    api({ method, url, data })
      .then((res) => {
        changeRefreshing(false);
        getAll();
        showSnack(res.data.message, "success");
      })
      .catch((err) => {
        console.log(err);
        changeRefreshing(false);
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  const handleExport = (selected) => {
    let data = JSON.stringify(selected);
    let filters_string = JSON.stringify(filters);
    let url = "";
    if (selected.length > 0) {
      if (filters && filters.length > 0) {
        url = `${MODULE_URL}/export?selected=${data}&&filters=${filters_string}`;
      } else {
        url = `${MODULE_URL}/export?selected=${data}`;
      }
    } else {
      if (filters && filters.length > 0) {
        url = `${MODULE_URL}/export?&&filters=${filters_string}`;
      } else {
        url = `${MODULE_URL}/export`;
      }
    }
    changeLoading(true);
    api
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "projects.xlsx"); //or any other extension
        document.body.appendChild(link);
        changeLoading(false);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        changeLoading(false);
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  const handleStatusChange = (id, value) => {
    changeLoading(true);
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("is_active", value);
    api
      .post(`${MODULE_URL}/changeStatus/${id}`, formData)
      .then(() => {
        getAll();
      })
      .catch((err) => {
        console.log(err);
        changeLoading(false);
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  const handleOpenSorting = () => {
    changeLoading(true);
    let url = "";
    if (districtId) {
      url = `${MODULE_URL}/${districtId}/tree-by-district`;
    } else {
      url = `${MODULE_URL}/tree`;
    }
    api
      .get(url)
      .then((response) => {
        changeLoading(false);
        setSortDialog({ open: true, tree: response.data.data });
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };

  const handleSortingSuccess = (tree) => {
    changeLoading(true);
    let formData = new FormData();
    formData.append("_method", "put");
    formData.append("tree", JSON.stringify(tree));
    api
      .post(`${MODULE_URL}/tree`, formData)
      .then((response) => {
        setSortDialog({ open: false, tree: [] });
        changeLoading(false);
        showSnack(response.data.message, "success");
      })
      .catch((err) => {
        console.log(err);
        changeLoading(false);
        setSortDialog({ open: false, tree: [] });
        if (err && err.response && err.response.data) {
          showSnack(err.response.data.message, "error");
        }
      });
  };

  const handleImport = (file) => {
    changeRefreshing(true);
    let formData = new FormData();
    formData.append("import", file);
    api
      .post(`${MODULE_URL}/import`, formData, {
        responseType: "blob",
      })
      .then(async (res) => {
        const isJsonBlob = (data) =>
          data instanceof Blob && data.type === "application/json";
        const responseData = isJsonBlob(res?.data)
          ? await res?.data?.text()
          : res?.data || {};
        const responseJson =
          typeof responseData === "string"
            ? JSON.parse(responseData)
            : responseData;

        changeRefreshing(false);
        if (responseJson && typeof responseData === "string") {
          showSnack(responseJson.message, "success");
        } else {
          const file = res.data;
          const url = window.URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "failed-project-imports.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        window.location.reload();
        getAll();
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong while importing projects");
        changeRefreshing(false);
      });
  };

  return (
    <Fragment>
      <ProjectsListing
        handleFilter={handleFilter}
        data={items}
        handleDelete={handleDelete}
        handleExport={handleExport}
        handleOpenEditFrom={handleOpenEditFrom}
        handleStatusChange={handleStatusChange}
        handleOpenSorting={handleOpenSorting}
        handleImport={handleImport}
      />
      {items && items.length > 0 && (
        <Paginator
          {...pagination}
          handlePrev={handlePrev}
          handleNext={handleNext}
          handleLastPage={handleLastPage}
          handleFirstPage={handleFirstPage}
        />
      )}
      <ProjectsFormDialog
        handleClose={handleCloseItemsFormDialog}
        handleSuccess={() => {
          setItemDialog({ open: false, item: null });
          getAll();
        }}
        isOpen={itemDialog.open}
        id={itemDialog.item}
        districtId={districtId}
      />
      <ProjectsSorting
        open={sortDialog.open}
        handleClose={() => {
          setSortDialog({ open: false, tree: [] });
        }}
        handleSuccess={handleSortingSuccess}
        tree={sortDialog.tree}
      />
      <FAB callback={fabCallback} />
    </Fragment>
  );
}

export default Projects;
