import React from "react";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  textField: {},
});

function TextInput(props) {
  const [field, meta, validate] = useField(props);
  const { value, onChange, onBlur, name } = field;
  const { error, touched } = meta;
  const { label, grid, type, disabled, rowsMax } = props;
  const classes = useStyles();
  // let disabled = false;
  return (
    <Grid item xs={grid}>
      <TextField
        fullWidth
        label={label}
        name={name}
        className={classes.textField}
        value={value ? value : ""}
        onChange={onChange}
        onBlur={onBlur}
        helperText={error && touched && error}
        error={error && touched}
        type={type}
        disabled={disabled}
        rowsMax={rowsMax}
        validate={validate}
      />
    </Grid>
  );
}

export default TextInput;
