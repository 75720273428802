import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const useStyles = makeStyles({});

function MultiSelectInput(props) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [field, meta] = useField(props);
  const { name, onBlur } = field;
  const {
    label,
    grid,
    options,
    setFieldValue,
    value,
    closeMenuOnSelect,
    components,
  } = props;
  const { error, touched } = meta;
  useEffect(() => {
    if (value && Array.isArray(value) && options) {
      const items = [];
      options.forEach((option) => {
        if (value.includes(option.value)) {
          items.push(option);
        }
      });
      setSelectedItems(items);
    }
  }, [value, options]);

  useEffect(() => {
    let items = [...selectedItems];
    items = items.map((item) => item.value);
    setFieldValue(name, JSON.stringify(items));
  }, [selectedItems]);

  const handleChange = (items) => {
    setSelectedItems(items);
  };
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid rgb(55, 17, 109, 0.5)",
      // This line disable the blue border
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgb(55, 17, 109, 0.5)",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <Grid item xs={grid}>
      <InputLabel style={{ marginBottom: "1vw" }}>{label}</InputLabel>
      <Select
        id={name}
        name={name}
        options={options}
        isMulti
        onChange={handleChange}
        // onChange={(selected) => {
        //   selected.find((option) => option.value === "all")
        //     ? handleChange(options.slice(1))
        //     : handleChange(selected);
        // }}
        value={selectedItems}
        onBlur={onBlur}
        components={components}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPortalTarget={document.body}
        styles={style}
        menuPosition="fixed"
        menuPlacement="bottom"
        menuShouldScrollIntoView={false}
        menuShouldBlockScroll={true}
        // helperText={error && touched && error}
        errorText={error && touched}
      />
      <div>
        {touched && error && (
          <div style={{ fontSize: "14px", color: "rgb(244, 67, 54)" }}>
            {error}
          </div>
        )}
      </div>
    </Grid>
  );
}

export default MultiSelectInput;
