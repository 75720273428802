import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";

const useStyles = makeStyles({
  textField: {},
});

function UrlInput(props) {
  const [field, meta] = useField(props);
  const { value, onChange, onBlur, name } = field;
  const { error, touched } = meta;
  const { label, grid, type } = props;
  const classes = useStyles();
  return (
    <Grid item xs={grid}>
      <FormControl className={classes.textField} fullWidth>
        <InputLabel error={error && touched}>{label}</InputLabel>
        <Input
          name={name}
          type={type}
          value={value}
          error={error && touched}
          onChange={onChange}
          onBlur={onBlur}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Open link/url"
                onClick={() => {
                  if (value) {
                    window.open(value, "_blank");
                  }
                }}
              >
                <LinkIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        {error && touched && (
          <FormHelperText error={error && touched}>{error}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}

export default UrlInput;
