import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  Fragment,
} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";
import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import SelectInput from "../../components/InputFields/SelectInput";
import Select from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { yupRequiredTranslatableString } from "../../helpers/Validators";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 400,
    marginRight: "1vw",
    marginBottom: "1vw",
    marginTop: "1vw",
    border: "1px solid rgb(55, 17, 109, 0.5)",

    // This line disable the blue border
    boxShadow: "none",
    borderRadius: 4,
    "&:hover": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
      borderRadius: 4,
    },
  }),
};

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  name: "",
  description: "",
  submit_button_text: "",
  success_message: "",
  fail_message: "",
  content: "",
  module_id: null,
  content_id: null,
  action_after_submit: 0,
};

const MODULE_URL = "admin/forms";

function FormsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess, setFieldValue, values } = props;
  const [modules, setModules] = useState([]);
  const [items, setItems] = useState([]);
  const [moduleInfo, setModuleInfo] = useState("");
  const [action, setAction] = useState(0);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          let action = "";
          if (tmp.action_after_submit == 1) {
            action = { label: "Redirect", value: 1 };
          } else {
            action = { label: "Show Message", value: 0 };
          }
          if (tmp.screen) {
            let moduleInfo = {
              label: tmp.screen.name,
              value: tmp.screen.id,
            };
            setModuleInfo(moduleInfo);
          }
          setAction(action);
          setItem(tmp);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
      getModules();
    } else {
      setItem(empty);
      setAction(0);
      setModuleInfo("");
      setContent("");
    }
  }, [id, isOpen]);

  useEffect(() => {
    if (moduleInfo.value) {
      setContent("");
      getModulesContent(moduleInfo.value);
    }
  }, [moduleInfo.value]);

  useEffect(() => {
    if (action.value == 1) {
      getModules();
    }
  }, [action]);

  useEffect(() => {
    if (moduleInfo.value) {
      getModulesContent(moduleInfo.value);
    }
  }, [moduleInfo.value, item.content_id]);

  const getModules = () => {
    api
      .get("admin/screens/inOptions")
      .then((res) => {
        setModules(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getModulesContent = (moduleInfo) => {
    api
      .get(`admin/screens/${moduleInfo}/contentOptions`)
      .then((res) => {
        const contents = res.data.data;
        setItems(contents);
        let content = "";
        let ContentID = item.content_id;
        if (contents.length <= 0) {
          setFieldValue("content_id", null);
        } else {
          contents.map((item) => {
            if (item.value == ContentID) {
              setContent(item);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };
  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              disabled={isDisabled()}
              onClick={handleSubmit}
              style={{
                color: headerTextColor,
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              name: yupRequiredTranslatableString(),
              submit_button_text: yupRequiredTranslatableString(),
              success_message: yupRequiredTranslatableString(),
              fail_message: yupRequiredTranslatableString(),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = {
                ...values,
                module_id: moduleInfo ? moduleInfo.value : null,
                content_id: content ? content.value : null,
                action_after_submit: action.value,
              };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                  setItem(empty);
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                    Object.keys(err.response.data).map((key) => {
                      showSnack(`${key}: ${err.response.data[key]}`, "error");
                    });
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue, touched } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    {item.is_default || item.name == "Referral" ? (
                      <TranslatableTextInput
                        translations={item.allTranslations}
                        setFieldValue={setFieldValue}
                        name="name"
                        label="Please enter form name"
                        grid={6}
                        customStyles
                        disabled
                      />
                    ) : (
                      <TranslatableTextInput
                        translations={item.allTranslations}
                        setFieldValue={setFieldValue}
                        name="name"
                        label="Please enter form name"
                        grid={6}
                      />
                    )}

                    <TranslatableTextAreaInput
                      translations={item.allTranslations}
                      name="description"
                      label="Description"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="submit_button_text"
                      label="Please enter submit button text"
                      grid={12}
                    />
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="success_message"
                      label="Please enter success message"
                      grid={6}
                    />
                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="fail_message"
                      label="Please enter fail message"
                      grid={6}
                    />
                  </Grid>
                  <Grid container md={12} style={{ marginTop: "1vw" }}>
                    <Grid item md={4}>
                      <Typography variant="p">
                        <b>Select an action after submit:</b>
                      </Typography>
                      <Select
                        name="action_after_submit"
                        label="Select an action"
                        styles={customStyles}
                        grid={4}
                        options={[
                          { label: "Show Message", value: 0 },
                          { label: "Redirect", value: 1 },
                        ]}
                        value={action}
                        onChange={(value) => setAction(value)}
                        setFieldError={setFieldError}
                        errors={errors}
                      />
                      <p className="MuiFormHelperText-root Mui-error">
                        {touched &&
                          errors.action_after_submit &&
                          errors.action_after_submit[0]}
                      </p>
                    </Grid>
                    {action.value == 1 && (
                      <Fragment>
                        <Grid item md={4}>
                          <Typography variant="p">
                            <b>Select an module:</b>
                          </Typography>
                          <Select
                            name="module_id"
                            label="Select modules"
                            styles={customStyles}
                            grid={4}
                            options={modules}
                            value={moduleInfo}
                            onChange={(value) => setModuleInfo(value)}
                            setFieldValue={setFieldValue}
                          />
                          <p className="MuiFormHelperText-root Mui-error">
                            {touched && errors.module_id}
                          </p>
                        </Grid>
                        <Grid item md={4}>
                          <Typography variant="p">
                            <b>Select an content:</b>
                          </Typography>
                          <Select
                            name="content_id"
                            label="Select content"
                            styles={customStyles}
                            grid={4}
                            options={items}
                            disabled={items.length <= 0}
                            value={content}
                            onChange={(value) => setContent(value)}
                            setFieldValue={setFieldValue}
                          />
                          <p className="MuiFormHelperText-root Mui-error">
                            {touched && errors.content_id}
                          </p>
                        </Grid>
                      </Fragment>
                    )}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default FormsFormDialog;
