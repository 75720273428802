import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import {
  isEmail,
  yupPhone,
  yupRequiredTranslatableString,
} from "../../helpers/Validators";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  name: "",
  is_active: 1,
  cover_image: [],
  gallery_image: [],
  email: "",
  phone: "",
};

const MODULE_URL = "admin/departments";

function DepartmentsFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          if (tmp.email) {
            tmp.email = tmp.email.split(",");
          }
          setItem(tmp);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              disabled={isDisabled()}
              onClick={handleSubmit}
              style={{
                color: headerTextColor,
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              name: yupRequiredTranslatableString(),
              phone: yupPhone(),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, values, setFieldError, setFieldValue } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    {item.name == "General" ? (
                      <TranslatableTextInput
                        translations={item.allTranslations}
                        setFieldValue={setFieldValue}
                        name="name"
                        label="Please enter department name"
                        grid={6}
                        disabled={true}
                      />
                    ) : (
                      <TranslatableTextInput
                        translations={item.allTranslations}
                        setFieldValue={setFieldValue}
                        name="name"
                        label="Please enter department name"
                        grid={6}
                      />
                    )}
                    <TextInput name="phone" label="Phone number" grid={6} />
                    <Grid container>
                      <FieldArray
                        name="email"
                        render={(arrayHelpers) => (
                          <>
                            {values.email &&
                              values.email.length > 0 &&
                              values.email.map((item, index) => (
                                <React.Fragment key={index}>
                                  <TextInput
                                    name={`email.${index}`}
                                    validate={(value) => {
                                      let error;

                                      if (!value) {
                                        error =
                                          "Please remove this row if not needed or write valid email format";
                                      } else if (!isEmail(value)) {
                                        error = "Invalid email address";
                                      }

                                      return error;
                                    }}
                                    label="Please enter department email"
                                    grid={6}
                                  />
                                  <Grid item xs={6}>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      Remove email
                                    </Button>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add email
                            </Button>
                          </>
                        )}
                      />
                    </Grid>
                    {item.name !== "General" && (
                      <SwitchInput
                        name="is_active"
                        label="Is Active"
                        grid={4}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default DepartmentsFormDialog;
