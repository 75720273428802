import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import SelectInput from "../../components/InputFields/SelectInput";
import { yupRequiredTranslatableString } from "../../helpers/Validators";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const MODULE_URL = "admin/unique_feature";

function UniqueFeaturesFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();
  const { id, isOpen, handleSuccess, projectId } = props;
  const empty = {
    name: "",
    description: "",
    is_active: 1,
    sort: 0,
    project_id: projectId ? parseInt(projectId) : 0,
    gallery_image: [],
  };
  const [item, setItem] = useState(empty);
  const [projects, setProjects] = useState([]);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          setItem(res.data.data);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
    if (isOpen) {
      getProjects();
    }
  }, [id, isOpen]);

  const getProjects = () => {
    changeRefreshing(true);
    api
      .get("admin/projects/options")
      .then((res) => {
        let data = res.data.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        setProjects(data);
        changeRefreshing(false);
      })
      .catch((err) => {
        changeRefreshing(false);
        console.log(err);
      });
  };

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              disabled={isDisabled()}
              onClick={handleSubmit}
              style={{
                color: headerTextColor,
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              title: yupRequiredTranslatableString(),
              description: yupRequiredTranslatableString(),
              project_id: Yup.number().required().min(1, "required"),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = { ...values };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <TranslatableTextInput
                      key="name"
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="title"
                      label="Please enter title"
                      grid={6}
                    />
                    <TextInput
                      name="sort"
                      label="Please enter sort"
                      grid={6}
                      type="number"
                    />
                    <SelectInput
                      name="project_id"
                      label="Select a project"
                      grid={6}
                      options={projects}
                      setFieldValue={setFieldValue}
                    />
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={6}
                      setFieldValue={setFieldValue}
                    />
                    <TranslatableTextAreaInput
                      key="description"
                      translations={item.allTranslations}
                      name="description"
                      label="Description"
                      grid={12}
                      setFieldValue={setFieldValue}
                    />
                    <GalleryInput
                      name="gallery"
                      label="Gallery Images"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default UniqueFeaturesFormDialog;
