import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import moment from "moment";

import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import DateTimeInput from "../../components/InputFields/DateTimeInput";
import UrlInput from "../../components/InputFields/UrlInput";
import ImageInput from "../../components/InputFields/ImageInput";
import GalleryInput from "../../components/InputFields/GalleryInput";
import SelectInput from "../../components/InputFields/SelectInput";
import Content from "./Content";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  title: "",
  link: "",
  screen_id: 0,
  parameters: "",
  is_active: 1,
  link_type: 0,
  image: [],
  details_id: 0,
};

const MODULE_URL = "admin/home_page_banners";

function HomePageBannersFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [screens, setScreens] = useState([]);
  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let data = res.data.data;
          setItem(data);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
    }
  }, [id, isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              disabled={isDisabled()}
              onClick={handleSubmit}
              style={{
                color: headerTextColor,
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(256, "Must be 256 characters or less")
                .required("Required"),
              // link: yupLink(),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = {
                ...values,
              };

              if (values.link_type == 0 || values.link_type == 1) {
                delete input.screen_id;
              }
              if (values.screen_id) {
                input.screen_id = values.screen_id;
              }
              if (values.screen_id == null) {
                delete input.screen_id;
              }

              if (values.details_id) {
                input.details_id = values.details_id;
              }
              if (values.details_id == 0) {
                delete input.details_id;
              }

              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }
              input.parameters = input.parameters ? input.parameters : null;

              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, setFieldError, setFieldValue, values } = props;
              return (
                <form>
                  <Grid container spacing={3}>
                    <TextInput
                      name="title"
                      label="Please enter banner title"
                      grid={12}
                    />
                    <SelectInput
                      name="link_type"
                      label="Select Banner link type"
                      grid={12}
                      setFieldValue={setFieldValue}
                      options={[
                        { label: "NONE", value: 0 },
                        { label: "External", value: 1 },
                        { label: "Internal", value: 2 },
                      ]}
                    />
                    <TextInput
                      name="sort"
                      label="Please enter sort"
                      grid={6}
                      type="number"
                    />
                    {values.link_type == 1 && (
                      <UrlInput
                        name="link"
                        label="Please enter the banner External link"
                        grid={6}
                      />
                    )}
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={6}
                      setFieldValue={setFieldValue}
                    />
                    {values.link_type == 2 && (
                      <Content
                        errors={errors}
                        values={values}
                        setFieldError={setFieldError}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <ImageInput
                      name="image"
                      label="Banner Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default HomePageBannersFormDialog;
