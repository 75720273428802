import * as Yup from "yup";

export function isImage(file) {
  if (file) {
    let ValidImageTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/svg+xml",
    ];
    let found = ValidImageTypes.indexOf(file["type"]);
    return found === -1 ? false : true;
  }
  return false;
}

export function isicon(file) {
  if (file) {
    let ValidiconTypes = ["image/gif", "image/jpeg", "image/png"];
    let found = ValidiconTypes.indexOf(file["type"]);
    return found === -1 ? false : true;
  }
  return false;
}

export function isDocument(file) {
  if (file) {
    let ValidImageTypes = [
      "text/plain",
      "application/msword",
      "application/pdf",
    ];
    // let found = ValidImageTypes.indexOf(file["type"]);
    // return found === -1 ? false : true;
    return ValidImageTypes.indexOf(file["type"]) !== -1;
  }
  return false;
}

export function isEmail(value) {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
}

export function isObject(value) {
  return typeof value === "object";
}

export function yupRequiredTranslatableString(lang = "[en]") {
  return Yup.string().matches(
    `"([^"]${lang})"\\s*:\\s*"([^"]+)",?`, // json formatting {"en": "value"}
    "Required field"
  );
}

export function yupLink() {
  return Yup.string().url();
}

export function yupPhone() {
  return Yup.string()
    .min(4)
    .matches(
      "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$",
      "Enter correct phone!"
    );
}
