import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { api } from "../../constants/Constants";
import AppContext from "../../context/AppContext";
import { transformObjectToFromData } from "../../helpers/General";
import TranslatableTextInput from "../../components/InputFields/TranslatableTextInput";
import TranslatableTextAreaInput from "../../components/InputFields/TranslatableTextAreaInput";
import TextInput from "../../components/InputFields/TextInput";
import TextAreaInput from "../../components/InputFields/TextAreaInput";
import SwitchInput from "../../components/InputFields/SwitchInput";
import ImageInput from "../../components/InputFields/ImageInput";
import makeAnimated from "react-select/animated";
import ProjectInput from "../../components/InputFields/ProjectsInput";
import {
  isEmail,
  yupRequiredTranslatableString,
} from "../../helpers/Validators";
const animatedComponents = makeAnimated();

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: 400,
    border: "1px solid rgb(55, 17, 109, 0.5)",
    // This line disable the blue border
    boxShadow: "none",
    borderRadius: "4px",

    "&:hover": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
    },
    "&:focus": {
      border: "1px solid rgb(55, 17, 109, 0.5)",
    },
  }),
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const styles = (theme) => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const empty_image = { id: null, url: "temp.png" };
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const empty = {
  name: "",
  is_active: 1,
  cover_image: [],
  icon_image: [],
  // working_days: [],
  gallery_image: [],
  description: "",
  email: "",
  is_all: 1,
};

const MODULE_URL = "admin/services_categories";

function ServicescategoriesFormDialog(props) {
  const classes = useStyles();
  const formRef = useRef();

  const [item, setItem] = useState(empty);
  const context = useContext(AppContext);
  const { themeObject, changeLoading, showSnack, changeRefreshing } = context;
  const { id, isOpen, handleSuccess } = props;
  const [selectOptions, setSelectOptions] = useState([]);
  const [parentID, setParentID] = useState(0);

  let categories = props.categories.map(({ id, name }) => {
    if (item.id === id) {
      return { value: id, label: name, isDisabled: true };
    } else {
      return {
        value: id,
        label: name,
      };
    }
  });

  useEffect(() => {
    if (id && isOpen) {
      changeLoading(true);
      api
        .get(`${MODULE_URL}/${id}`)
        .then((res) => {
          let tmp = res.data.data;
          if (tmp.email) {
            tmp.email = tmp.email.split(",");
          }
          setItem(tmp);
          changeLoading(false);
        })
        .catch((err) => {
          changeLoading(false);
          console.log(err);
        });
    } else {
      setItem(empty);
      setParentID([]);
    }
  }, [id, isOpen]);

  const getFormValues = () => {
    const form = formRef.current;
    if (!form) {
      return {};
    }
    return form.values;
  };

  const isDisabled = () => {
    const values = getFormValues();
    return false;
  };

  const handleSubmit = (e) => {
    const form = formRef.current;
    form.handleSubmit(e);
  };

  const headerTextColor = themeObject.headerText ?? "#fff";

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid rgb(55, 17, 109, 0.5)",
      // This line disable the blue border
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgb(55, 17, 109, 0.5)",
      },
    }),
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.isOpen}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="subtitle2" className={classes.flex}>
              {id ? "Update" : "Create"}
            </Typography>
            <Button
              disabled={isDisabled()}
              onClick={handleSubmit}
              style={{
                color: headerTextColor,
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={item}
            validationSchema={Yup.object({
              name: yupRequiredTranslatableString(),
              description: yupRequiredTranslatableString(),
            })}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setFieldError }
            ) => {
              await new Promise((r) => setTimeout(r, 500));
              changeRefreshing(true);
              setSubmitting(true);
              let method = "POST";
              let url = `${MODULE_URL}`;
              const input = {
                ...values,
                parent_id: 0,
              };
              if (id) {
                input._method = "PUT";
                url += "/" + id;
              }

              const data = transformObjectToFromData(input);
              api({ method, url, data })
                .then((res) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  handleSuccess();
                  showSnack(res.data.message, "success");
                })
                .catch((err) => {
                  changeRefreshing(false);
                  setSubmitting(false);
                  if (err.response) {
                    setErrors(err.response.data);
                    setFieldError(err.response.data);
                  }
                });
            }}
          >
            {(props) => {
              const { errors, values, setFieldError, setFieldValue } = props;

              return (
                <form>
                  <Grid container spacing={3}>
                    <SwitchInput
                      name="is_all"
                      label="Select All Projects"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    {values.is_all == 0 && (
                      <ProjectInput
                        setFieldValue={setFieldValue}
                        value={item.related_projects}
                      />
                    )}

                    <TranslatableTextInput
                      translations={item.allTranslations}
                      setFieldValue={setFieldValue}
                      name="name"
                      label="Please enter category name"
                      grid={6}
                    />

                    <>
                      {/* <Grid container>
                        <FieldArray
                          name="email"
                          render={(arrayHelpers) => (
                            <>
                              {values.email &&
                                values.email.length > 0 &&
                                values.email.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <TextInput
                                      name={`email.${index}`}
                                      label="Please enter email"
                                      grid={6}
                                      validate={(value) => {
                                        let error;

                                        if (!value) {
                                          error =
                                            "Please remove this row if not needed or write valid email format";
                                        } else if (!isEmail(value)) {
                                          error = "Invalid email address";
                                        }

                                        return error;
                                      }}
                                    />
                                    <Grid item xs={6}>
                                      <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        Remove email
                                      </Button>
                                    </Grid>
                                  </React.Fragment>
                                ))}
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => arrayHelpers.push("")}
                              >
                                Add email
                              </Button>
                            </>
                          )}
                        />
                      </Grid> */}
                      <TranslatableTextAreaInput
                        translations={item.allTranslations}
                        name="description"
                        label="Description"
                        grid={12}
                        setFieldValue={setFieldValue}
                      />
                    </>
                    {/* )} */}
                    <SwitchInput
                      name="is_active"
                      label="Is Active"
                      grid={4}
                      setFieldValue={setFieldValue}
                    />
                    <ImageInput
                      name="icon_image"
                      label="Icon Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    <ImageInput
                      name="cover_image"
                      label="Cover Image"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    />
                    {/* <GalleryInput
                      name="gallery"
                      label="Gallery Images"
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      errors={errors}
                      grid={12}
                    /> */}
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}

export default ServicescategoriesFormDialog;
