import React, { useState, useEffect, useContext } from "react";
import { useField } from "formik";
import { Button, IconButton, InputLabel, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/HighlightOff";
import { isObject } from "../../helpers/Validators";
import AppContext from "../../context/AppContext";

const useStyles = makeStyles({
  imageInput: {
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: 20,
    alignItems: "center",
  },
  buttonUpload: {
    color: "white",
  },
  preview: {
    color: "white",
    maxWidth: "100%",
    width: "120px !important",
    maxHeight: "initial",
    padding: "0px !important",
    // paddingTop: "-100% !important",
    position: "relative !important",
    // marginBottom: " -40px",
    // marginLeft: "20px",
  },
  errorText: {
    textAlign: "center",
    color: "red",
  },
});

const empty_image = { id: null, url: "temp.png" };
function ImageInput(props) {
  const [field, meta] = useField(props);
  const { value, onChange, onBlur, name } = field;
  const { error, touched } = meta;
  const { label, grid, setFieldValue } = props;
  const classes = useStyles();

  const [image, setImage] = useState(empty_image);

  const context = useContext(AppContext);
  const { openMediaLibrary, closeMediaLibrary, mediaLibrary } = context;

  const handleEventListener = (event) => {
    if (event.detail && event.detail.tag === name) {
      handleImageSelected(event.detail.files);
    }
  };

  useEffect(() => {
    if (mediaLibrary.open && mediaLibrary.tag === name) {
      document.addEventListener("media_selected", handleEventListener);
    } else {
      document.removeEventListener("media_selected", handleEventListener);
    }
    return () => {
      document.removeEventListener("media_selected", handleEventListener);
    };
  }, [mediaLibrary]);

  useEffect(() => {
    // in case of initial value is object
    if (value && isObject(value)) {
      setFieldValue(name, value.id);
      setImage(value);
    }
  }, [value]);

  const handleImageSelected = (file) => {
    setFieldValue(name, file.id);
    setImage(file);
    closeMediaLibrary();
  };

  const renderErrors = () => {
    if (error) {
      if (Array.isArray(error)) {
        return error.map((err) => (
          <InputLabel className={classes.errorText}>{err}</InputLabel>
        ));
      } else {
        return <InputLabel className={classes.errorText}>{error}</InputLabel>;
      }
    } else {
      return;
    }
  };
  return (
    <Grid item xs={grid}>
      <div className={classes.imageInput}>
        <div>
          <Button
            variant="contained"
            className={classes.buttonUpload}
            color="primary"
            onClick={() => {
              openMediaLibrary(name, false);
            }}
          >
            Upload
          </Button>
        </div>
        <div style={{ position: "relative", marginTop: "15px" }}>
          <IconButton
            style={{
              position: "absolute",
              zIndex: 9,
              top: 0,
              right: 0,
              padding: "5px",
            }}
            aria-label="remove"
            onClick={() => {
              handleImageSelected(empty_image);
            }}
          >
            <CloseIcon color="secondary" fontSize="large" />
          </IconButton>
          {image.type == "document" ? (
            <>
              <img className={classes.preview} src="pdf.png" alt="" />
              <InputLabel style={{ textAlign: "center" }}>
                {image.title}
              </InputLabel>
            </>
          ) : (
            <img className={classes.preview} src={image.url} alt="" />
          )}

          <InputLabel style={{ textAlign: "center" }}>{label}</InputLabel>
        </div>
        {renderErrors()}
      </div>
    </Grid>
  );
}

export default ImageInput;
